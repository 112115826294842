import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Table,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { formatToReal, isValidCPF } from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCPF } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import { BOA_VISTA_CONTANTS } from "../../utils/boa-vista-variables";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import { GoAlertFill } from "react-icons/go";

const AcertaBvCPFQuery = ({ queryId, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    queryId,
    data
  );

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    valor = maskCPF(valor);

    setInfo(valor);
  };

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_ACERTA_BV}/${info.replace(/\D/g, "")}?type=cpf`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CPF inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data.data.consultaCredito);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const checkColorScore = (score) => {
    if (score >= 800) {
      return "#01B000";
    } else if (score >= 600) {
      return "#8DED01";
    } else if (score >= 400) {
      return "#e5e000";
    } else if (score >= 200) {
      return "#FFBC00";
    } else {
      return "#db2e45";
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Acerta BV CPF
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score",
          "Pendências financeiras",
          "Protestos",
          "Cheques sem fundos",
          "Cheques sustados",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-start">
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2">
                <div className="block">
                  <Label htmlFor="info" value="CPF:" />
                </div>
                <TextInput
                  id="info"
                  type="text"
                  required
                  onChange={handleChange}
                  maxLength="14"
                  value={info}
                />
              </div>
              <Button
                className=" shadow-lg primary flex items-center justify-center"
                type="submit"
                isProcessing={isLoading}
                processingSpinner={
                  <CgSpinner className="h-5 w-5 animate-spin" />
                }
                style={{ height: "43px" }}
              >
                <FaSearch />
              </Button>
            </div>
            {!errorQuery && data && (
              <Button
                className={`w-full md:w-fit shadow-lg secondary text-center ${
                  data && !isLoading ? "flex" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </CardQueryDescription>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`${data || isLoading ? "block" : "hidden"}`}
            ref={componentRef}
          >
            {!isLoading ? (
              <div className="relative overflow-x-hidden">
                <Card className="mt-2">
                  <HeaderQuery
                    imgHeader="/assets/logo_boa_vista.png"
                    size={"small"}
                  />
                  <table className="border boder-gray-200 rounded">
                    <thead>
                      <tr>
                        <td className="p-2 font-semibold text-primary">
                          Renda presumida
                        </td>
                      </tr>
                    </thead>
                    <tbody style={{ background: "#E9EDEE" }}>
                      <tr>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Faixa/Classe
                          </div>
                          <div>{data?.score?.score}</div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Renda Mensal
                          </div>
                          <div>{data?.dadosCadastrais?.rendaPresumida}</div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="p-2 bg-white font-semibold text-primary"
                          colSpan={2}
                        >
                          {data?.dadosCadastrais?.textoRendaPresumida}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="border boder-gray-200 p-2">
                    <div className="font-semibold text-primary mb-3">
                      Novo risco 6 meses
                    </div>
                    <div className="flex gap-2">
                      <div
                        className="rounded h-40 flex justify-center items-center text-white text-center px-6 text-3xl font-semibold w-2/5"
                        style={{
                          background: `${checkColorScore(+data?.score?.score)}`,
                        }}
                      >
                        Score <br /> {+data?.score?.score}
                      </div>
                      <div className="rounded h-40 flex justify-center items-center border border-gray-200 px-6 font-semibold">
                        {data?.score?.mensagem}
                      </div>
                      <div className="w-4/5 min-w-4/5 pt-3">
                        <ReactApexChart
                          options={{
                            chart: {
                              type: "radialBar",
                              offsetY: -20,
                              sparkline: {
                                enabled: true,
                              },
                            },
                            plotOptions: {
                              radialBar: {
                                startAngle: -90,
                                endAngle: 90,
                                track: {
                                  background: "#e7e7e7",
                                  strokeWidth: "97%",
                                  margin: 5, // margin is in pixels
                                  dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: "#999",
                                    opacity: 1,
                                    blur: 2,
                                  },
                                },
                                dataLabels: {
                                  name: {
                                    show: false,
                                  },
                                  value: {
                                    offsetY: -2,
                                    fontSize: "22px",
                                    formatter: function (val) {
                                      return val * 10;
                                    },
                                  },
                                },
                              },
                            },
                            grid: {
                              padding: {
                                top: -10,
                              },
                            },
                            fill: {
                              type: "solid",
                              gradient: {
                                shade: "light",
                                shadeIntensity: 0.4,
                                inverseColors: false,
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 50, 53, 91],
                              },
                              colors: (() => {
                                return checkColorScore(+data?.score?.score);
                              })(),
                            },
                          }}
                          series={[+data?.score?.score / 10]}
                          type="radialBar"
                        />
                        <div className="text-sm text-center">
                          Probabilidade de indadimplência:{" "}
                          {+data?.score?.probabilidade / 100}%
                        </div>
                      </div>
                    </div>
                  </div>

                  <table className="border boder-gray-200 rounded">
                    <thead>
                      <tr>
                        <td className="p-2 font-semibold text-primary">
                          Painel de controle
                        </td>
                      </tr>
                    </thead>
                    <tbody style={{ background: "#E9EDEE" }}>
                      <tr className="border-b border-white">
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Ocorrência
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            Quantidade
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            Última
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            Valor (R$)
                          </div>
                        </td>
                      </tr>
                      <tr className="border-b border-white">
                        <td className="p-2">
                          <a
                            href="#debitos"
                            className="font-semibold text-primary underline"
                          >
                            {+data?.resumoConsulta?.pendenciasFinanceiras
                              ?.quantidadeTotal > 0 && (
                              <GoAlertFill className="inline-block mr-2" />
                            )}
                            Registro de débitos
                          </a>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {data?.resumoConsulta?.pendenciasFinanceiras
                              ?.quantidadeTotal || "-"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            -
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            R${" "}
                            {data?.resumoConsulta?.pendenciasFinanceiras
                              ?.valorTotal || "-"}
                          </div>
                        </td>
                      </tr>

                      <tr className="border-b border-white">
                        <td className="p-2">
                          <a
                            href="#protestos"
                            className="font-semibold text-primary underline"
                          >
                            {+data?.resumoConsulta?.protestos?.quantidadeTotal >
                              0 && (
                              <GoAlertFill className="inline-block mr-2" />
                            )}
                            Protestos
                          </a>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {data?.resumoConsulta?.protestos?.quantidadeTotal ||
                              "-"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {"-"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            R$ {data?.resumoConsulta?.protestos?.valorTotal}
                          </div>
                        </td>
                      </tr>
                      <tr className="border-b border-white">
                        <td className="p-2">
                          <a
                            href="#consultas"
                            className="font-semibold text-primary underline"
                          >
                            Consultas anteriores{" "}
                          </a>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {+data?.historicoConsultas?.length}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            -
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            -
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2">
                          <a
                            href="#devolucao-usuario"
                            className="font-semibold text-primary underline"
                          >
                            {+data?.resumoConsulta?.chequesSustados
                              ?.quantidadeTotal > 0 && (
                              <GoAlertFill className="inline-block mr-2" />
                            )}
                            Devoluções Informadas pelo Usuário{" "}
                          </a>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {data?.resumoConsulta?.chequesSustados
                              ?.quantidadeTotal || "-"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {"-"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {"-"}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2">
                          <a
                            href="#cheque-sem-fundo"
                            className="font-semibold text-primary underline"
                          >
                            {+data?.resumoConsulta?.chequesSemFundo
                              ?.quantidadeTotal > 0 && (
                              <GoAlertFill className="inline-block mr-2" />
                            )}
                            Cheques sem fundo
                          </a>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {data?.resumoConsulta?.chequesSemFundo
                              ?.quantidadeTotal || "-"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {"-"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {"-"}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-2">
                          <a
                            href="#cheque-21"
                            className="font-semibold text-primary underline"
                          >
                            {data?.cheques_sustados_pelo_motivo_21?.registro ===
                              "S" && (
                              <GoAlertFill className="inline-block mr-2" />
                            )}
                            Cheque Sustado Motivo 21{" "}
                          </a>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {"-"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {"-"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary text-center">
                            {"-"}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="border boder-gray-200 rounded">
                    <thead>
                      <tr>
                        <td className="p-2 font-semibold text-primary">
                          Identificação
                        </td>
                      </tr>
                    </thead>
                    <tbody style={{ background: "#E9EDEE" }}>
                      <tr className="border-b border-white">
                        <td className="p-2">
                          <div className="font-semibold text-primary">Nome</div>
                          <div>{data?.dadosCadastrais?.nome}</div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">CPF</div>
                          <div>{info}</div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Nome da mãe
                          </div>
                          <div>
                            {data?.dadosCadastrais?.nomeMae || "Não informado"}
                          </div>
                        </td>
                      </tr>
                      <tr className="border-b border-white">
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Situação do CPF
                          </div>
                          <div>
                            {BOA_VISTA_CONTANTS.SITUATION[
                              data?.dadosCadastrais?.situacao
                            ] || "Não informado"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Data de Atualização
                          </div>
                          <div>{"Não informado"}</div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Região de Origem do CPF
                          </div>
                          <div>
                            {BOA_VISTA_CONTANTS.DOCUMENT_ORIGIN[
                              data?.dadosCadastrais?.regiaoCPF
                            ] || "Não informado"}
                          </div>
                        </td>
                      </tr>
                      <tr className="border-b border-white">
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Data de Nascimento
                          </div>
                          <div>
                            {data?.dadosCadastrais?.dataNascimento ||
                              "Não informado"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Nacionalidade
                          </div>
                          <div>{"Não informado"}</div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">Sexo</div>
                          <div>
                            {BOA_VISTA_CONTANTS.SEX[
                              data?.dadosCadastrais?.sexo
                            ] || "Não informado"}
                          </div>
                        </td>
                      </tr>
                      <tr className="border-b border-white">
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Grau de Instrução
                          </div>
                          <div>
                            {BOA_VISTA_CONTANTS.DEGREE_OF_EDUCATION[
                              data?.dadosCadastrais?.grauInstrucao
                            ] || "Não informado"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Quantidade de dependentes
                          </div>
                          <div>
                            {data?.dadosCadastrais?.numeroDependentes ||
                              "Não informado"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Estado Civil
                          </div>
                          <div>
                            {BOA_VISTA_CONTANTS.CIVIL_STATE[
                              data?.dadosCadastrais?.estadoCivil
                            ] || "Não informado"}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2" colSpan={3}>
                          <div className="font-semibold text-primary">
                            Título de Eleitor
                          </div>
                          <div>
                            {data?.dadosCadastrais?.tituloEleitor ||
                              "Não informado"}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="border boder-gray-200 rounded">
                    <thead>
                      <tr>
                        <td className="p-2 font-semibold text-primary">
                          Localização
                        </td>
                      </tr>
                    </thead>
                    <tbody style={{ background: "#E9EDEE" }}>
                      <tr className="border-b border-white">
                        <td className="p-2" colSpan={2}>
                          <div className="font-semibold text-primary">
                            Endereço
                          </div>
                          <div>
                            {data?.dadosCadastrais?.endereco || "Não informado"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Bairro
                          </div>
                          <div>
                            {data?.dadosCadastrais?.bairro || "Não informado"}
                          </div>
                        </td>
                      </tr>
                      <tr className="border-b border-white">
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Cidade
                          </div>
                          <div>
                            {data?.dadosCadastrais?.cidade || "Não informado"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">UF</div>
                          <div>
                            {data?.dadosCadastrais?.uf || "Não informado"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">CEP</div>
                          <div>
                            {data?.dadosCadastrais?.cep || "Não informado"}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table id="debitos" className="border boder-gray-200 rounded">
                    <thead>
                      <tr>
                        <td
                          colSpan={9}
                          className="p-2 font-semibold text-primary"
                        >
                          Registros de débito
                        </td>
                      </tr>
                    </thead>
                    {data?.pendenciasFinanceiras?.length > 0 ? (
                      <tbody
                        style={{ background: "#E9EDEE" }}
                        className="text-sm"
                      >
                        <tr className="border-b border-white">
                          <td className="p-2 text-center" colSpan={9}>
                            <span className="font-semibold text-primary">
                              Valor total:{" "}
                            </span>
                            <span>
                              R${" "}
                              {
                                data?.resumoConsulta?.pendenciasFinanceiras
                                  ?.valorTotal
                              }
                            </span>
                          </td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="p-2" colSpan={4}>
                            <span className="font-semibold text-primary">
                              Total de registros do avalista:{" "}
                            </span>
                            <span>
                              {data?.resumoConsulta?.pendenciasFinanceiras
                                ?.quantidadeTotal || "Não informado"}
                            </span>
                          </td>
                          <td className="p-2" colSpan={5}>
                            <span className="font-semibold text-primary">
                              Total de registros do comprador:{" "}
                            </span>
                            <span>
                              {data?.resumo_ocorrencias_de_debitos
                                ?.totalDevedor || "Não informado"}
                            </span>
                          </td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="p-2" colSpan={4}>
                            <span className="font-semibold text-primary">
                              Data do primeiro registro:{" "}
                            </span>
                            <span>
                              {data?.resumo_ocorrencias_de_debitos
                                ?.dataPrimeiroDebito || "Não informado"}
                            </span>
                          </td>
                          <td className="p-2" colSpan={5}>
                            <span className="font-semibold text-primary">
                              Valor do primeiro (R$):{" "}
                            </span>
                            <span>
                              {data?.resumo_ocorrencias_de_debitos
                                ?.valorPrimeiroDebito || "Não informado"}
                            </span>
                          </td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="p-2" colSpan={4}>
                            <span className="font-semibold text-primary">
                              Data do maior registro:{" "}
                            </span>
                            <span>
                              {data?.resumo_ocorrencias_de_debitos
                                ?.dataMaiorDebito || "Não informado"}
                            </span>
                          </td>
                          <td className="p-2" colSpan={5}>
                            <span className="font-semibold text-primary">
                              Valor do maior (R$):{" "}
                            </span>
                            <span>
                              {data?.resumo_ocorrencias_de_debitos
                                ?.valorMaiorDebito || "Não informado"}
                            </span>
                          </td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Ocorrência
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Informante
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Tipo de ocorrência
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Contrato
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Cidade
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">UF</div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Situação
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Valor (R$)
                            </div>
                          </td>
                        </tr>

                        {data?.pendenciasFinanceiras?.map((debito, index) => (
                          <tr
                            key={index}
                            className={`${index % 2 === 0 && "bg-white"}`}
                          >
                            <td className="text-center py-2">{debito.data}</td>
                            <td className="text-center py-2">
                              {debito.informante}
                            </td>
                            <td className="text-center py-2">
                              {debito.tipoPendencia}
                            </td>
                            <td className="text-center py-2">-</td>
                            <td className="text-center py-2">
                              {debito.cidade}
                            </td>
                            <td className="text-center py-2">{debito.uf}</td>
                            <td className="text-center py-2">
                              {debito.situacao}
                            </td>
                            <td className="text-center py-2">{debito.valor}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody style={{ background: "#E9EDEE" }}>
                        <tr>
                          <td className="text-center p-2" colSpan={9}>
                            Nada consta
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                  <table
                    id="protestos"
                    className="border boder-gray-200 rounded"
                  >
                    <thead>
                      <tr>
                        <td
                          colSpan={9}
                          className="p-2 font-semibold text-primary"
                        >
                          Protestos
                        </td>
                      </tr>
                    </thead>
                    {data?.protestos?.length > 0 ? (
                      <tbody
                        style={{ background: "#E9EDEE" }}
                        className="text-sm"
                      >
                        <tr className="border-b border-white">
                          <td className="p-2" colSpan={3}>
                            <span className="font-semibold text-primary">
                              Total dos títulos protestados:{" "}
                            </span>
                            <span>
                              {data?.resumoConsulta?.protestos
                                ?.quantidadeTotal || "Não informado"}
                            </span>
                          </td>
                          <td className="p-2" colSpan={2}>
                            <span className="font-semibold text-primary">
                              Valor total(R$):{" "}
                            </span>
                            <span>
                              {data?.resumoConsulta?.protestos?.valorTotal ||
                                "Não informado"}
                            </span>
                          </td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="p-2" colSpan={3}>
                            <span className="font-semibold text-primary">
                              Primeiro título protestado em:{" "}
                            </span>
                            <span>{"Não informado"}</span>
                          </td>
                          <td className="p-2" colSpan={2}>
                            <span className="font-semibold text-primary">
                              Último título protestado em:{" "}
                            </span>
                            <span>{"Não informado"}</span>
                          </td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Data do protesto
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Cartório
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Cidade
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">UF</div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Valor (R$)
                            </div>
                          </td>
                        </tr>

                        {data?.protestos?.map((protesto, index) => (
                          <tr
                            key={index}
                            className={`${index % 2 === 0 && "bg-white"}`}
                          >
                            <td className="text-center py-2">
                              {protesto.data}
                            </td>
                            <td className="text-center py-2">
                              {protesto.cartorio}
                            </td>
                            <td className="text-center py-2">
                              {protesto.cidade}
                            </td>
                            <td className="text-center py-2">-</td>
                            <td className="text-center py-2">
                              {protesto.valor}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody style={{ background: "#E9EDEE" }}>
                        <tr>
                          <td className="text-center p-2" colSpan={5}>
                            Nada consta
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                  <table
                    id="consultas"
                    className="border boder-gray-200 rounded"
                  >
                    <thead>
                      <tr>
                        <td
                          colSpan={3}
                          className="p-2 font-semibold text-primary"
                        >
                          Consultas anteriores
                        </td>
                      </tr>
                    </thead>
                    {data?.historicoConsultas?.length > 0 ? (
                      <tbody
                        style={{ background: "#E9EDEE" }}
                        className="text-sm"
                      >
                        <tr className="border-b border-white">
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Data
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Informante
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Tipo de ocorrência
                            </div>
                          </td>
                        </tr>

                        {data?.historicoConsultas?.map((consulta, index) => (
                          <tr
                            key={index}
                            className={`${index % 2 === 0 && "bg-white"}`}
                          >
                            <td className="text-center py-2">
                              {consulta.data}
                            </td>
                            <td className="text-center py-2">
                              {consulta.empresa}
                            </td>
                            <td className="text-center py-2">-</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody style={{ background: "#E9EDEE" }}>
                        <tr>
                          <td className="text-center p-2" colSpan={3}>
                            Nada consta
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                  <table
                    id="devolucao-usuario"
                    className="border boder-gray-200 rounded"
                  >
                    <thead>
                      <tr>
                        <td
                          colSpan={4}
                          className="p-2 font-semibold text-primary"
                        >
                          Cheques devoluções informadas pelo usuário
                        </td>
                      </tr>
                    </thead>
                    {data?.chequesSustados?.length > 0 ? (
                      <tbody
                        style={{ background: "#E9EDEE" }}
                        className="text-sm"
                      >
                        <tr className="border-b border-white">
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Nome
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Banco
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Agência
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Documento
                            </div>
                          </td>
                        </tr>

                        {data?.chequesSustados?.map((cheque, index) => (
                          <tr
                            key={index}
                            className={`${index % 2 === 0 && "bg-white"}`}
                          >
                            <td className="text-center py-2">
                              {cheque.nome || "-"}
                            </td>
                            <td className="text-center py-2">
                              {cheque.banco || "-"}
                            </td>
                            <td className="text-center py-2">
                              {cheque.agencia || "-"}
                            </td>
                            <td className="text-center py-2">
                              {cheque?.numeroDocumento || "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody style={{ background: "#E9EDEE" }}>
                        <tr>
                          <td className="text-center p-2" colSpan={4}>
                            Nada consta
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                  <table
                    id="cheque-sem-fundo"
                    className="border boder-gray-200 rounded"
                  >
                    <thead>
                      <tr>
                        <td
                          colSpan={4}
                          className="p-2 font-semibold text-primary"
                        >
                          Cheques sem fundo
                        </td>
                      </tr>
                    </thead>
                    {data?.chequesSemFundo?.length > 0 ? (
                      <tbody
                        style={{ background: "#E9EDEE" }}
                        className="text-sm"
                      >
                        <tr className="border-b border-white">
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Nome
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Banco
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Agência
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Documento
                            </div>
                          </td>
                        </tr>

                        {data?.chequesSemFundo?.map((cheque, index) => (
                          <tr
                            key={index}
                            className={`${index % 2 === 0 && "bg-white"}`}
                          >
                            <td className="text-center py-2">
                              {cheque?.nome || "-"}
                            </td>
                            <td className="text-center py-2">
                              {cheque?.banco || "-"}
                            </td>
                            <td className="text-center py-2">
                              {cheque?.agencia || "-"}
                            </td>
                            <td className="text-center py-2">
                              {cheque?.numeroDocumento || "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody style={{ background: "#E9EDEE" }}>
                        <tr>
                          <td className="text-center p-2" colSpan={4}>
                            Nada consta
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                  <table
                    id="cheque-21"
                    className="border boder-gray-200 rounded"
                  >
                    <thead>
                      <tr>
                        <td
                          colSpan={4}
                          className="p-2 font-semibold text-primary"
                        >
                          Cheque sustado motivo 21 (contra ordem ou oposição ao
                          pagamento)
                        </td>
                      </tr>
                    </thead>
                    {data?.cheques_sustados_pelo_motivo_21?.registro === "S" ? (
                      <tbody
                        style={{ background: "#E9EDEE" }}
                        className="text-sm"
                      >
                        <tr className="border-b border-white">
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Nome
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Banco
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Agência
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Documento
                            </div>
                          </td>
                        </tr>
                        {Array.isArray(
                          data?.cheques_sustados_pelo_motivo_21
                        ) ? (
                          <>
                            {data?.cheques_sustados_pelo_motivo_21?.map(
                              (devolucao, index) => (
                                <tr
                                  key={index}
                                  className={`${index % 2 === 0 && "bg-white"}`}
                                >
                                  <td className="text-center py-2">
                                    {devolucao.nome}
                                  </td>
                                  <td className="text-center py-2">
                                    {devolucao.nomeBanco}
                                  </td>
                                  <td className="text-center py-2">
                                    {devolucao.agencia}
                                  </td>
                                  <td className="text-center py-2">
                                    {devolucao.numeroDocumento}
                                  </td>
                                </tr>
                              )
                            )}
                          </>
                        ) : (
                          <tr>
                            <td className="text-center py-2">
                              {data?.cheques_sustados_pelo_motivo_21?.nome}
                            </td>
                            <td className="text-center py-2">
                              {data?.cheques_sustados_pelo_motivo_21?.nomeBanco}
                            </td>
                            <td className="text-center py-2">
                              {data?.cheques_sustados_pelo_motivo_21?.agencia}
                            </td>
                            <td className="text-center py-2">
                              {
                                data?.cheques_sustados_pelo_motivo_21
                                  ?.numeroDocumento
                              }
                            </td>
                          </tr>
                        )}
                      </tbody>
                    ) : (
                      <tbody style={{ background: "#E9EDEE" }}>
                        <tr>
                          <td className="text-center p-2" colSpan={4}>
                            Nada consta
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Card>
              </div>
            ) : (
              <Card>
                <div role="status" className="w-full animate-pulse">
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                </div>
              </Card>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AcertaBvCPFQuery;
