import { Alert, Badge, Button, Card } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { formatToReal, isValidCNPJ, isValidCPF } from "../../../utils/utils";
import axios from "../../../config/axiosInstance";
import { QUERIES } from "../../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import usePDF from "../../../hooks/usePDF";
import CardQueryDescription from "../../common/CardQueryDescription";
import moment from "moment";
import QueryInput from "../../inputs/QueryInput";

const ConsultaSimplesQueryKL = ({ queryId, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [total, setTotal] = useState(0);
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    queryId,
    data
  );

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_CONSULTA_SIMPLES_KL}/${info.replace(
        /\D/g,
        ""
      )}?queryId=${queryId}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setDocument(info);
    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    } else {
      if (!isValidCNPJ(unmaskedValue)) {
        setError("CNPJ inválido!");
        isValid = false;
      }
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (data) {
      let total = data?.refin_pefin?.reduce(
        (acc, curr) =>
          acc +
          parseFloat(
            curr.valor
              .replace("R$", "")
              .replace(/\./g, "")
              .replace(",", ".")
              .trim()
          ),
        0
      );

      total += data?.scpc?.reduce(
        (acc, curr) =>
          acc +
          parseFloat(
            curr.valor
              .replace("R$", "")
              .replace(/\./g, "")
              .replace(",", ".")
              .trim()
          ),
        0
      );

      total += data?.protestos?.reduce(
        (acc, curr) =>
          acc +
          parseFloat(
            curr.valor
              .replace("R$", "")
              .replace(/\./g, "")
              .replace(",", ".")
              .trim()
          ),
        0
      );
      setTotal(total);
    }
  }, [data]);

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Consulta Simples
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={["Serasa", "Boa Vista", "Protestos", "Cheques sem fundo"]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-2 items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF/CNPJ"
            />
            {!errorQuery && data && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
        </form>
      </CardQueryDescription>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`${data || isLoading ? "block" : "hidden"}`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="mt-2">
                  <div className="flex items-center justify-between mb-5">
                    <img className="w-40" src="/assets/logo_serasa.png" />
                    <img
                      className="w-40 mt-3"
                      src="/assets/logo_boa_vista.png"
                    />
                  </div>

                  <div>
                    <div className="font-bold uppercase mb-1">
                      Informações gerais
                    </div>
                    <div className="mb-1 flex items-center gap-3">
                      <div className="uppercase w-1/4">Nome do cliente: </div>
                      <div>
                        {document?.length === 14
                          ? data?.cadastral?.NOME
                          : data?.cadastral?.RAZAO_SOCIAL}
                      </div>
                    </div>
                    <div className="mb-1 flex items-center gap-3">
                      <div className="uppercase w-1/4">CPF/CNPJ: </div>
                      <div>{document}</div>
                    </div>
                    <div className="mb-4 flex items-center gap-3">
                      <div className="uppercase w-1/4">Data: </div>
                      <div>{moment().format("DD/MM/yyyy HH:mm:ss")}</div>
                    </div>
                  </div>
                  <div className="overflow-scroll-x">
                    <table className="rounded w-full">
                      <thead>
                        <tr>
                          <td
                            className="p-1 font-semibold text-white text-center uppercase text-sm"
                            style={{ background: "#fa6633" }}
                            colSpan={4}
                          >
                            Serasa
                          </td>
                        </tr>
                      </thead>
                      {data?.refin_pefin?.length > 0 ? (
                        <tbody>
                          <tr>
                            <td className="p-2">
                              <div className="font-semibold">Data</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">Tipo</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">Valor</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">Origem</div>
                            </td>
                          </tr>
                          {data?.refin_pefin?.map((serasa, index) => (
                            <tr
                              key={index}
                              style={{
                                background: `${index % 2 === 0 && "#f7f9fb"}`,
                              }}
                            >
                              <td className="p-2">
                                <div>{serasa.data}</div>
                              </td>
                              <td className="p-2">
                                <div>{serasa.tipo}</div>
                              </td>
                              <td className="p-2">
                                <div>{serasa.valor}</div>
                              </td>
                              <td className="p-2">
                                <div>{serasa.empresa}</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td className="text-center uppercase" colSpan={4}>
                              [Nada consta]
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="overflow-scroll-x">
                    <table className="rounded mt-4 w-full">
                      <thead>
                        <tr>
                          <td
                            className="p-1 font-semibold text-white text-center uppercase text-sm"
                            style={{ background: "#fa6633" }}
                            colSpan={4}
                          >
                            Boa Vista
                          </td>
                        </tr>
                      </thead>
                      {data?.scpc?.length > 0 ? (
                        <tbody>
                          <tr>
                            <td className="p-2">
                              <div className="font-semibold">Ocorrência</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">Nome</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">Valor</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold text-center">
                                Disponibilidade
                              </div>
                            </td>
                          </tr>
                          {data?.scpc?.map((scpc, index) => (
                            <tr
                              key={index}
                              style={{
                                background: `${index % 2 === 0 && "#f7f9fb"}`,
                              }}
                            >
                              <td className="p-2">
                                <div>{scpc.data}</div>
                              </td>
                              <td className="p-2">
                                <div>{scpc.nome}</div>
                              </td>
                              <td className="p-2">
                                <div>{scpc.valor}</div>
                              </td>
                              <td className="p-2 text-center">
                                <div>{scpc.data_disponivel}</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td className="text-center uppercase" colSpan={4}>
                              [Nada consta]
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="overflow-scroll-x">
                    <table className="rounded mt-4 w-full">
                      <thead>
                        <tr>
                          <td
                            className="p-1 font-semibold text-white text-center uppercase text-sm"
                            style={{ background: "#fa6633" }}
                            colSpan={4}
                          >
                            Cheques sem fundo
                          </td>
                        </tr>
                      </thead>
                      {data?.cheques?.length > 0 ? (
                        <tbody>
                          <tr>
                            <td className="p-2">
                              <div className="font-semibold">Ocorrência</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">Nome</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">Valor</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">
                                Disponibilidade
                              </div>
                            </td>
                          </tr>
                          {data?.cheques?.map((cheque, index) => (
                            <tr
                              key={index}
                              style={{
                                background: `${index % 2 === 0 && "#f7f9fb"}`,
                              }}
                            >
                              <td className="p-2">
                                <div>{cheque?.data}</div>
                              </td>
                              <td className="p-2">
                                <div>{cheque?.nome}</div>
                              </td>
                              <td className="p-2">
                                <div>{cheque?.valor}</div>
                              </td>
                              <td className="p-2">
                                <div>-</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td className="text-center uppercase" colSpan={4}>
                              [Nada consta]
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="overflow-scroll-x">
                    <table className="rounded mt-4 w-full">
                      <thead>
                        <tr>
                          <td
                            className="p-1 font-semibold text-white text-center uppercase text-sm"
                            style={{ background: "#fa6633" }}
                            colSpan={5}
                          >
                            Protestos
                          </td>
                        </tr>
                      </thead>
                      {data?.protestos?.length > 0 ? (
                        <tbody>
                          <tr>
                            <td className="p-2">
                              <div className="font-semibold">Data</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">Valor</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">Cartório</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">Cidade</div>
                            </td>
                            <td className="p-2">
                              <div className="font-semibold">UF</div>
                            </td>
                          </tr>
                          {data?.protestos?.map((protesto, index) => (
                            <tr
                              key={index}
                              style={{
                                background: `${index % 2 === 0 && "#f7f9fb"}`,
                              }}
                            >
                              <td className="p-2">
                                <div>{protesto.data}</div>
                              </td>
                              <td className="p-2">
                                <div>{protesto.valor}</div>
                              </td>
                              <td className="p-2">
                                <div>{protesto.cartorio} cartório</div>
                              </td>
                              <td className="p-2">
                                <div>{protesto.cidade}</div>
                              </td>
                              <td className="p-2">
                                <div>{protesto.uf}</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td className="text-center uppercase" colSpan={4}>
                              [Nada consta]
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>

                  <div
                    style={{ background: "#fa6633" }}
                    className="text-white uppercase text-sm p-1 font-semibold mb-1 text-center mb-8"
                  >
                    Total: {formatToReal(total || 0)}
                  </div>

                  <div>
                    <div
                      style={{ background: "#fa6633" }}
                      className="text-white uppercase text-sm p-2 font-semibold mb-3"
                    >
                      Alertas/Informações fixas
                    </div>
                    <div>
                      <div className="font-semibold">
                        INFORMAÇÕES IMPORTANTES{" "}
                      </div>
                      <div>
                        ESTAS INFORMAÇÕES SÃO CONFIDENCIAIS E DEVERÃO SER
                        UTILIZADAS, ÚNICA E EXCLUSIVAMENTE, PARA ORIENTAÇÃO DAS
                        TRANSAÇÕES COMERCIAIS DO USUÁRIO , RESPONSABILIZANDO-SE
                        CIVIL E CRIMINALMENTE POR DANOS QUE OCASIONAR A
                        TERCEIROS, QUANDO UTILIZADAS EM DESACORDO COM A
                        LEGISLAÇÃO EM VIGOR. EM CASO DE DÚVIDAS ENTRE EM CONTATO
                        PELO FALE CONOSCO.
                      </div>
                      <div className="font-semibold mt-3"> AVISO </div>
                      <div>
                        AINDA QUE EMPREGANDO OS MELHORES ESFORCOS, NOSSA EMPRESA
                        SE EXIME DE QUALQUER RESPONSABILIDADE PELA EVENTUAL NAO
                        INCLUSAO DE ALGUM REGISTRO EM RAZAO DE ATRASO OU FALTA
                        DO ENCAMINHAMENTO DOS DADOS PELOS DIVERSOS PROVEDORES DE
                        INFORMACOES CONVENIADOS. EM CASO DE DUVIDAS ENTRE EM
                        CONTATO COM NOSSO SUPORTE.{" "}
                      </div>
                      <div className="font-semibold mt-3">
                        MINUTA DE DECLARAÇÃO LGPD
                      </div>
                      <div>
                        DECLARO QUE A UTILIZAÇÃO DOS DADOS RESPEITARÁ AS
                        FINALIDADES E PROCEDIMENTOS LEGALMENTE ADMITIDOS PELA
                        LEI GERAL DE PROTEÇÃO DE DADOS DIVULGADOS EM MINHA
                        PÁGINA INICIAL DE ACESSO.
                      </div>
                    </div>
                  </div>
                </Card>
              </>
            ) : (
              <Card>
                <div role="status" className="w-full animate-pulse">
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                </div>
              </Card>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ConsultaSimplesQueryKL;
