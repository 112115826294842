import { Button, Label, TextInput } from "flowbite-react";
import { maskCNPJ, maskCPF } from "../../utils/masks";
import { CgSpinner } from "react-icons/cg";
import { FaSearch } from "react-icons/fa";

const QueryInput = ({ info, setInfo, isLoading, error, type = "CPF" }) => {
  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    if (type === "CPF") {
      valor = maskCPF(valor);
    } else if (type === "CNPJ") {
      valor = maskCNPJ(valor);
    } else {
      if (valor.length <= 11) {
        valor = maskCPF(valor);
      } else {
        valor = maskCNPJ(valor);
      }
    }

    setInfo(valor);
  };

  return (
    <div className="flex items-center gap-2">
      <div className="block">
        <Label htmlFor="info" value={`${type}:`} />
      </div>
      <TextInput
        id="info"
        type="text"
        required
        onChange={handleChange}
        maxLength={type === "CPF" ? `14` : `18`}
        value={info}
      />
      <Button
        className=" shadow-lg primary flex items-center justify-center"
        type="submit"
        isProcessing={isLoading}
        processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
        style={{ height: "43px" }}
      >
        <FaSearch />
      </Button>
      {error && <small className="text-red-600">{type} inválido!</small>}
    </div>
  );
};

export default QueryInput;
