import { useState } from "react";
import axios from "axios";
import { saveUserInWindow } from "../utils/utils";

const useLogin = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = async (accessCode, password) => {
    setLoading(true);
    try {

      const response = await axios.post(url, {
        id: accessCode,
        password: password,
        tokenFCM: localStorage.getItem("tokenFCM")
      });

      localStorage.setItem("token", response.data.token);
      saveUserInWindow();

      setIsLoggedIn(true);
      setLoading(false);
    } catch (error) {
      setError(error);
      setIsLoggedIn(false);
      setLoading(false);
      throw error;
    }
  };

  return { loading, error, isLoggedIn, login };
};

export default useLogin;
