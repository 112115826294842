import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Table,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { formatToReal, isValidCNPJ, isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import CardQueryDescription from "../common/CardQueryDescription";
import moment from "moment";
import QueryInput from "../inputs/QueryInput";

const SCPCNetQuery = ({ queryId, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const [document, setDocument] = useState("");
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    queryId,
    data
  );

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES.GET_SCPC_NET}/${info.replace(/\D/g, "")}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    } else {
      if (!isValidCNPJ(unmaskedValue)) {
        setError("CNPJ inválido!");
        isValid = false;
      }
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">SCPC Net</div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Registros de débitos",
          "Protestos",
          "Consultas anteriores ao SCPC",
          "Cheques sem fundo",
          "Consultas anteriores de telefone",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF/CNPJ"
            />
            {!errorQuery && data && (
              <Button
                className={`w-full md:w-fit shadow-lg secondary text-center ${
                  data && !isLoading ? "flex" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
        </form>
      </CardQueryDescription>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`${data || isLoading ? "block" : "hidden"}`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="mt-2">
                  <HeaderQuery
                    imgHeader="/assets/logo_boa_vista.png"
                    size={"small"}
                  />

                  <div
                    className="text-center p-2 text-white rounded-xl mb-1 text-2xl"
                    style={{ background: "#17375e" }}
                  >
                    SCPC NET
                  </div>

                  <table className="rounded text-xs md:text-base">
                    <thead>
                      <tr>
                        <td
                          className="p-2 font-semibold text-center rounded-xl"
                          style={{ background: "#8eb4e3" }}
                          colSpan={4}
                        >
                          Dados Informados na Consulta
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ background: "#cae6ee" }}>
                        <td className="p-2 border-r-4 border-white" colSpan={1}>
                          <div>Número:</div>
                        </td>
                        <td className="p-2" colSpan={3}>
                          <div className="font-semibold">*******</div>
                        </td>
                      </tr>
                      <tr style={{ background: "#e7f5f8" }}>
                        <td className="p-2 border-r-4 border-white" colSpan={1}>
                          <div>Solicitante:</div>
                        </td>
                        <td className="p-2" colSpan={3}>
                          <div className="font-semibold">
                            ******** - ************
                          </div>
                        </td>
                      </tr>
                      <tr style={{ background: "#cae6ee" }}>
                        <td className="p-2 border-r-4 border-white" colSpan={1}>
                          <div>Documento:</div>
                        </td>
                        <td className="p-2" colSpan={3}>
                          <div className="font-semibold">{document?.length === 14 ? "CPF" : "CNPJ"} {document}</div>
                        </td>
                      </tr>
                      <tr style={{ background: "#e7f5f8" }}>
                        <td className="p-2 border-r-4 border-white" colSpan={1}>
                          <div>Tipo de resposta:</div>
                        </td>
                        <td className="p-2" colSpan={3}>
                          <div className="font-semibold">
                            Sem informações do CHEQUE e sem SCORE CRÉDITO
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="overflow-x-scroll">
                    <table className="rounded w-full text-xs md:text-base">
                      <thead>
                        <tr>
                          <td
                            className="p-2 font-semibold text-center rounded-xl"
                            style={{ background: "#8eb4e3" }}
                            colSpan={4}
                          >
                            Dados Cadastrais
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ background: "#cae6ee" }}>
                          <td
                            className="p-2 border-r-4 border-white"
                            colSpan={1}
                          >
                            <div>Nome:</div>
                          </td>
                          <td className="p-2" colSpan={3}>
                            <div className="font-semibold">
                              {data?.CREDCADASTRAL?.DADOS_RECEITA_FEDERAL?.NOME}
                            </div>
                          </td>
                        </tr>
                        <tr style={{ background: "#e7f5f8" }}>
                          <td className="p-2 border-r-4 border-white">
                            <div>Documento:</div>
                          </td>
                          <td className="p-2 border-r-4 border-white">
                            <div className="font-semibold">{document?.length === 14 ? "CPF" : "CNPJ"} {document}</div>
                          </td>
                          <td className="p-2 border-r-4 border-white">
                            <div>T. Eleitor:</div>
                          </td>
                          <td className="p-2">
                            <div className="font-semibold">*********</div>
                          </td>
                        </tr>
                        <tr style={{ background: "#cae6ee" }}>
                          <td
                            className="p-2 border-r-4 border-white"
                            colSpan={1}
                          >
                            <div>Data de Nascimento:</div>
                          </td>
                          <td className="p-2" colSpan={3}>
                            <div className="font-semibold">
                              {
                                data?.CREDCADASTRAL?.DADOS_RECEITA_FEDERAL
                                  ?.DATA_NASCIMENTO_FUNDACAO
                              }
                            </div>
                          </td>
                        </tr>
                        <tr style={{ background: "#e7f5f8" }}>
                          <td
                            className="p-2 border-r-4 border-white"
                            colSpan={1}
                          >
                            <div>Nome da Mãe:</div>
                          </td>
                          <td className="p-2" colSpan={3}>
                            <div className="font-semibold">
                              {
                                data?.CREDCADASTRAL?.DADOS_RECEITA_FEDERAL
                                  ?.NOME_MAE
                              }
                            </div>
                          </td>
                        </tr>
                        <tr style={{ background: "#e7f5f8" }}>
                          <td
                            className="p-2 border-r-4 border-white"
                            colSpan={1}
                          >
                            <div>Situação do CPF:</div>
                          </td>
                          <td className="p-2" colSpan={3}>
                            <div className="font-semibold">Regular</div>
                          </td>
                        </tr>
                        <tr style={{ background: "#e7f5f8" }}>
                          <td className="p-2 border-r-4 border-white">
                            <div>Atualizado em:</div>
                          </td>
                          <td className="p-2 border-r-4 border-white">
                            <div className="font-semibold">**/**/****</div>
                          </td>
                          <td className="p-2 border-r-4 border-white">
                            <div>Hora:</div>
                          </td>
                          <td className="p-2">
                            <div className="font-semibold">**:**</div>
                          </td>
                        </tr>
                        <tr style={{ background: "#e7f5f8" }}>
                          <td
                            className="p-2 border-r-4 border-white"
                            colSpan={1}
                          >
                            <div>Cod. Controle:</div>
                          </td>
                          <td className="p-2" colSpan={3}>
                            <div className="font-semibold">******</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div>
                    <table className="rounded w-full text-xs md:text-base">
                      <thead>
                        <tr>
                          <td
                            className="p-2 font-semibold text-center rounded-xl"
                            style={{ background: "#8eb4e3" }}
                            colSpan={4}
                          >
                            Registros de Débitos SCPC
                          </td>
                        </tr>
                      </thead>
                    </table>
                    {data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.OCORRENCIAS
                      ?.length > 0 ? (
                      <>
                        <div className="flex gap-2 items-center">
                          <img src="/assets/danger.png" className="mt-1" />
                          <div
                            className="font-semibold text-sm p-2 mt-2 flex-1"
                            style={{ background: "#cae6ee" }}
                          >
                            Possui{" "}
                            {
                              data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                ?.QUANTIDADE_OCORRENCIA
                            }{" "}
                            débitos no SCPC | Valor total de
                            {data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.VALOR_TOTAL
                              ? formatToReal(
                                  data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.VALOR_TOTAL.replace(
                                    ",",
                                    "."
                                  )
                                )
                              : "-"}{" "}
                            | Primeiro em{" "}
                            {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                              ?.DATA_PRIMEIRO || "-"}{" "}
                            | Último em{" "}
                            {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                              ?.ULTIMO_VENCIMENTO || "-"}
                          </div>
                        </div>
                        <table className="rounded w-full mt-4">
                          <thead>
                            <tr>
                              <td
                                className="p-2 font-semibold text-white"
                                style={{ background: "#17375E" }}
                                colSpan={1}
                              >
                                Tipo
                              </td>
                              <td
                                className="p-2 font-semibold text-white"
                                style={{ background: "#17375E" }}
                                colSpan={1}
                              >
                                Informante
                              </td>
                              <td
                                className="p-2 font-semibold text-white"
                                style={{ background: "#17375E" }}
                                colSpan={1}
                              >
                                Valor
                              </td>
                              <td
                                className="p-2 font-semibold text-white"
                                style={{ background: "#17375E" }}
                                colSpan={1}
                              >
                                Débito
                              </td>
                              <td
                                className="p-2 font-semibold text-white"
                                style={{ background: "#17375E" }}
                                colSpan={1}
                              >
                                Disponível
                              </td>
                              <td
                                className="p-2 font-semibold text-white"
                                style={{ background: "#17375E" }}
                                colSpan={1}
                              >
                                Cidade
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.OCORRENCIAS?.map(
                              (pendencia, index) => (
                                <tr
                                  style={{
                                    background:
                                      index % 2 === 0 ? "#cae6ee" : "#e7f5f8",
                                  }}
                                  key={index}
                                  className="text-sm"
                                >
                                  <td className="p-2">
                                    <div>{pendencia.TIPO_DEVEDOR}</div>
                                  </td>
                                  <td className="p-2">
                                    <div>{pendencia.CREDOR}</div>
                                  </td>
                                  <td className="p-2">
                                    <div>
                                      {pendencia.VALOR
                                        ? formatToReal(
                                            pendencia.VALOR.replace(",", ".")
                                          )
                                        : "-"}
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div>{pendencia.DATA_VENCIMENTO}</div>
                                  </td>
                                  <td className="p-2">
                                    <div>{pendencia.DATA_INCLUSAO}</div>
                                  </td>
                                  <td className="p-2">
                                    <div>{pendencia.ORIGEM}</div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                      </table>
                      </>
                    ) : (
                      <div className="flex gap-2 items-center">
                        <img src="/assets/checked.png" className="mt-1" />
                        <div
                          className="p-2 mt-2 flex-1 text-center"
                          style={{ background: "#cae6ee" }}
                        >
                          Nada consta
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    <table className="rounded w-full">
                      <thead>
                        <tr>
                          <td
                            className="p-2 font-semibold text-center rounded-xl"
                            style={{ background: "#8eb4e3" }}
                            colSpan={4}
                          >
                            Títulos Protestados - Últimos 5 Anos
                          </td>
                        </tr>
                      </thead>
                    </table>
                    <div className="flex gap-2 items-center">
                      <img src="/assets/checked.png" className="mt-1" />
                      <div
                        className="p-2 mt-2 flex-1 text-center"
                        style={{ background: "#cae6ee" }}
                      >
                        Nada consta
                      </div>
                    </div>
                  </div>

                  <div className="overflow-x-scroll">
                    <table className="rounded w-full text-xs md:text-base">
                      <thead>
                        <tr>
                          <td
                            className="p-2 font-semibold text-center rounded-xl"
                            style={{ background: "#8eb4e3" }}
                            colSpan={4}
                          >
                            Consultas Anteriores ao SCPC
                          </td>
                        </tr>
                      </thead>
                    </table>
                    {data?.CREDCADASTRAL?.PASSAGENS_COMERCIAIS?.OCORRENCIAS
                      ?.length > 0 ? (
                      <>
                        <table className="rounded w-full mt-4">
                          <thead>
                            <tr>
                              <td
                                className="p-2 font-semibold text-white"
                                style={{ background: "#17375E" }}
                                colSpan={1}
                              >
                                Data
                              </td>
                              <td
                                className="p-2 font-semibold text-white"
                                style={{ background: "#17375E" }}
                                colSpan={1}
                              >
                                Cliente
                              </td>
                              <td
                                className="p-2 font-semibold text-white"
                                style={{ background: "#17375E" }}
                                colSpan={1}
                              >
                                Telefone
                              </td>
                              <td
                                className="p-2 font-semibold text-white"
                                style={{ background: "#17375E" }}
                                colSpan={1}
                              >
                                UF
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.CREDCADASTRAL?.PASSAGENS_COMERCIAIS?.OCORRENCIAS?.map(
                              (consulta, index) => (
                                <tr
                                  style={{
                                    background:
                                      index % 2 === 0 ? "#cae6ee" : "#e7f5f8",
                                  }}
                                  key={index}
                                  className="text-sm"
                                >
                                  <td className="p-2">
                                    <div>{consulta.DATA_CONSULTA}</div>
                                  </td>
                                  <td className="p-2">
                                    <div>{consulta.CLIENTE_CONSULTA}</div>
                                  </td>
                                  <td className="p-2">
                                    <div>
                                      {consulta.TELEFONE_CLIENTE || "-"}
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div>
                                      {consulta.CIDADE_UF_CLIENTE || "-"}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div className="flex gap-2 items-center">
                        <img src="/assets/checked.png" className="mt-1" />
                        <div
                          className="p-2 mt-2 flex-1 text-center"
                          style={{ background: "#cae6ee" }}
                        >
                          Nada consta
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    <table className="rounded w-full">
                      <thead>
                        <tr>
                          <td
                            className="p-2 font-semibold text-center rounded-xl"
                            style={{ background: "#8eb4e3" }}
                            colSpan={4}
                          >
                            Informações de Cheque CCF
                          </td>
                        </tr>
                      </thead>
                    </table>
                    <div className="flex gap-2 items-center">
                      <img src="/assets/checked.png" className="mt-1" />
                      <div
                        className="p-2 mt-2 flex-1 text-center"
                        style={{ background: "#cae6ee" }}
                      >
                        Nada consta
                      </div>
                    </div>
                  </div>

                  <div className="overflow-x-scroll">
                    <table className="rounded w-full text-xs md:text-base">
                      <thead>
                        <tr>
                          <td
                            className="p-2 font-semibold text-center rounded-xl"
                            style={{ background: "#8eb4e3" }}
                            colSpan={4}
                          >
                            Consultas Anteriores de Telefone
                          </td>
                        </tr>
                      </thead>
                    </table>
                    <div className="flex gap-2 items-center">
                      <img src="/assets/checked.png" className="mt-1" />
                      <div
                        className="p-2 mt-2 flex-1 text-center"
                        style={{ background: "#cae6ee" }}
                      >
                        Nada consta
                      </div>
                    </div>
                  </div>

                  <div
                    className="p-3 font-semibold text-center rounded-xl"
                    style={{ background: "#8eb4e3" }}
                  >
                    + + + + + + INFORMAÇÕES CONFIDENCIAIS - SÃO PAULO,{" "}
                    {moment().format("DD/MM/yyyy HH:mm:ss")} NET9999
                  </div>
                </Card>
              </>
            ) : (
              <Card>
                <div role="status" className="w-full animate-pulse">
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                </div>
              </Card>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SCPCNetQuery;
