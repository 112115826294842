import { Alert, Button, Card, Label, Table, TextInput } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import {
  formatToReal,
  isValidCNPJ,
  isValidCPF,
  uploadPDF,
} from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCNPJ, maskCPF } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";

const ScrBacenScoreQuery = ({ queryId, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    queryId,
    data
  );

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    if (valor.length <= 11) {
      valor = maskCPF(valor);
    } else {
      valor = maskCNPJ(valor);
    }

    setInfo(valor);
  };

  const fetchInfo = async (type) => {
    return await axios(
      `${QUERIES.GET_SCR_BACEN_SCORE}/${info.replace(/\D/g, "")}?type=${type}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;
    let type = "F";

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    } else {
      if (!isValidCNPJ(unmaskedValue)) {
        setError("CNPJ inválido!");
        isValid = false;
      }
      type = "J";
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setErrorData("");
        const { data: dados } = await fetchInfo(type);
        setData(dados.data.data);
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">BACEN</div>

      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "SCR Score",
          "Créditos a vencer",
          "Créditos vencidos",
          "Limite de crédito",
          "Prejuízos",
          "Operações",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-5 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-2 items-end">
            <div className="flex items-center gap-2">
              <div className="block">
                <Label htmlFor="info" value="CPF/CNPJ:" />
              </div>
              <TextInput
                id="info"
                type="text"
                required
                onChange={handleChange}
                maxLength="18"
                value={info}
              />
            </div>
            <Button
              className="  primary flex items-center justify-center"
              type="submit"
              isProcessing={isLoading}
              processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
              style={{ height: "43px" }}
            >
              <FaSearch />
            </Button>
            {!errorData && data && (
              <Button
                className={`w-fit  secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </CardQueryDescription>

      {errorData && (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      )}
      {!errorData && (
        <div
          className={`${data || isLoading ? "block" : "hidden"}`}
          ref={componentRef}
        >
          {!isLoading ? (
            <Card>
              <HeaderQuery hideLogo={true} />
              <div>
                <TitleStripe>SCR Score</TitleStripe>
              </div>
              <div className="flex flex-col gap-2 md:flex-row items-center">
                <div className="w-full flex-1 p-4 mx-auto">
                  <ReactApexChart
                    options={{
                      chart: {
                        type: "radialBar",
                        offsetY: -20,
                        sparkline: {
                          enabled: true,
                        },
                      },
                      plotOptions: {
                        radialBar: {
                          startAngle: -90,
                          endAngle: 90,
                          track: {
                            background: "#e7e7e7",
                            strokeWidth: "97%",
                            margin: 5, // margin is in pixels
                            dropShadow: {
                              enabled: true,
                              top: 2,
                              left: 0,
                              color: "#999",
                              opacity: 1,
                              blur: 2,
                            },
                          },
                          dataLabels: {
                            name: {
                              show: false,
                            },
                            value: {
                              offsetY: -2,
                              fontSize: "22px",
                              formatter: function (val) {
                                return val * 10;
                              },
                            },
                          },
                        },
                      },
                      grid: {
                        padding: {
                          top: -10,
                        },
                      },
                      fill: {
                        type: "solid",
                        gradient: {
                          shade: "light",
                          shadeIntensity: 0.4,
                          inverseColors: false,
                          opacityFrom: 1,
                          opacityTo: 1,
                          stops: [0, 50, 53, 91],
                        },
                        colors: (() => {
                          const score =
                            +data?.CREDCADASTRAL?.RELATORIO_SCR?.SCORE
                              ?.PONTUACAO;
                          if (score >= 800) {
                            return "#01B000";
                          } else if (score >= 600) {
                            return "#8DED01";
                          } else if (score >= 400) {
                            return "#FDF700";
                          } else if (score >= 200) {
                            return "#FFBC00";
                          } else {
                            return "#E54B00";
                          }
                        })(),
                      },
                    }}
                    series={[
                      +data?.CREDCADASTRAL?.RELATORIO_SCR?.SCORE?.PONTUACAO /
                        10,
                    ]}
                    type="radialBar"
                  />
                </div>
                <div className="border border-gray-200 p-2 text-sm flex-1">
                  <span className="font-semibold">Importante:</span> a decisão
                  de aprovação ou não do crédito é de exclusiva responsabilidade
                  do concedente. as informações prestadas pelo provedor tem como
                  objevo subsidiar essas decisões e, em hipótese alguma devem
                  ser ulizadas como jusficava, pelo concedente do crédito, para
                  a tomada da referida decisão.
                </div>
              </div>

              <TitleStripe>Informações:</TitleStripe>
              <div className="flex flex-col md:flex-row gap-3 md:gap-20 text-sm">
                <div className="flex-1">
                  <div className="mb-3">
                    <span className="font-semibold">Documento: </span>
                    {data?.CREDCADASTRAL.RELATORIO_SCR?.DOCUMENTO}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Tipo: </span>
                    {data?.CREDCADASTRAL.RELATORIO_SCR?.TIPO_DOCUMENTO}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Quantidade operações:{" "}
                    </span>
                    {data?.CREDCADASTRAL.RELATORIO_SCR?.QUANTIDADE_OPERACOES}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Quantidade instituições:{" "}
                    </span>
                    {data?.CREDCADASTRAL.RELATORIO_SCR?.QUANTIDADE_INSTITUICOES}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Coobrigação assumida:{" "}
                    </span>
                    R$ {data?.CREDCADASTRAL.RELATORIO_SCR?.COOBRIGACAO_ASSUMIDA}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Coobrigação recebida:{" "}
                    </span>
                    R$ {data?.CREDCADASTRAL.RELATORIO_SCR?.COOBRIGACAO_RECEBIDA}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Início relacionamento:
                    </span>
                    {
                      data?.CREDCADASTRAL.RELATORIO_SCR
                        ?.DATA_INICIO_RELACIONAMENTO
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Data Base consultada:{" "}
                    </span>
                    {data?.CREDCADASTRAL.RELATORIO_SCR?.DATABASE_CONSULTADA}
                  </div>
                </div>
                <div className="flex-1">
                  <div className="mb-3">
                    <span className="font-semibold">
                      Percentual documentos processados:{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL.RELATORIO_SCR
                        ?.PERCENTUAL_DOCUMENTOS_PROCESSADOS
                    }
                    %
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Percentual volume processado:{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL.RELATORIO_SCR
                        ?.PERCENTUAL_VOLUME_PROCESSADO
                    }
                    %
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Quantidade operações(discordância):{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL.RELATORIO_SCR
                        ?.QUANTIDADE_OPERACOES_DISCORDANCIA
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Quantidade operações(subjudice):{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL.RELATORIO_SCR
                        ?.QUANTIDADE_OPERACOES_SUBJUDICE
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Responsabilidade total(discordância):{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL.RELATORIO_SCR
                        ?.RESPONSABILIDADE_TOTAL_DISCORDANCIA
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Responsabilidade total(subjudice):{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL.RELATORIO_SCR
                        ?.RESPONSABILIDADE_TOTAL_SUBJUDICE
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Risco indireto(vendor):{" "}
                    </span>
                    {data?.CREDCADASTRAL.RELATORIO_SCR?.RISCO_INDIRETO_VENDOR}
                  </div>
                </div>
              </div>

              <div>
                <TitleStripe className="mb-5">Crédito a vencer</TitleStripe>
                {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO
                  ?.CREDITO_AVENCER?.OPERACOES?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-red-300">
                            Crédito
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Valor
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Percentual
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white font-semibold">
                              Carteira ativa A
                            </Table.Cell>
                            <Table.Cell className="text-center font-semibold">
                              R${" "}
                              {
                                data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO
                                  ?.CREDITO_AVENCER?.VALOR
                              }
                            </Table.Cell>
                            <Table.Cell className="text-center font-semibold">
                              {
                                data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO
                                  ?.CREDITO_AVENCER?.PERCENTUAL
                              }
                              %
                            </Table.Cell>
                          </Table.Row>
                          {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.CREDITO_AVENCER?.OPERACOES?.map(
                            (credito, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                  {credito.DESCRICAO}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  R$ {credito.VALOR}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {credito.PERCENTUAL}%
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}

                <div className="mt-10">
                  <TitleStripe className="mb-5">Crédito vencido</TitleStripe>
                  {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO
                    ?.CREDITO_VENCIDO?.OPERACOES?.length > 0 ? (
                    <>
                      <div className="overflow-x-auto rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="bg-red-300">
                              Crédito
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Valor
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Percentual
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white font-semibold">
                                Vencido
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                R${" "}
                                {
                                  data?.CREDCADASTRAL?.RELATORIO_SCR
                                    ?.CONSOLIDADO?.CREDITO_VENCIDO?.VALOR
                                }
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {
                                  data?.CREDCADASTRAL?.RELATORIO_SCR
                                    ?.CONSOLIDADO?.CREDITO_VENCIDO?.PERCENTUAL
                                }
                                %
                              </Table.Cell>
                            </Table.Row>
                            {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.CREDITO_VENCIDO?.OPERACOES?.map(
                              (credito, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {credito.DESCRICAO}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    R$ {credito.VALOR}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {credito.PERCENTUAL}%
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <FaRegCheckCircle fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
                <div className="mt-10">
                  <TitleStripe className="mb-5">Limite de crédito</TitleStripe>
                  {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO
                    ?.LIMITE_CREDITO?.OPERACOES?.length > 0 ? (
                    <>
                      <div className="overflow-x-auto rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="bg-red-300">
                              Crédito
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Valor
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Percentual
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white font-semibold">
                                Limite de crédito
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                R${" "}
                                {
                                  data?.CREDCADASTRAL?.RELATORIO_SCR
                                    ?.CONSOLIDADO?.LIMITE_CREDITO?.VALOR
                                }
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {
                                  data?.CREDCADASTRAL?.RELATORIO_SCR
                                    ?.CONSOLIDADO?.LIMITE_CREDITO?.PERCENTUAL
                                }
                                %
                              </Table.Cell>
                            </Table.Row>
                            {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.LIMITE_CREDITO?.OPERACOES?.map(
                              (credito, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {credito.DESCRICAO}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    R$ {credito.VALOR}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {credito.PERCENTUAL}%
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <FaRegCheckCircle fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>

                <div className="mt-10">
                  <TitleStripe className="mb-5">Prejuízos</TitleStripe>
                  {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.PREJUIZO
                    ?.OPERACOES?.length > 0 ? (
                    <>
                      <div className="overflow-x-auto  rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="bg-red-300">
                              Prejuízo
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Valor
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Percentual
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white font-semibold">
                                Prejuízo
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                R${" "}
                                {
                                  data?.CREDCADASTRAL?.RELATORIO_SCR
                                    ?.CONSOLIDADO?.PREJUIZO?.VALOR
                                }
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {
                                  data?.CREDCADASTRAL?.RELATORIO_SCR
                                    ?.CONSOLIDADO?.PREJUIZO?.PERCENTUAL
                                }
                                %
                              </Table.Cell>
                            </Table.Row>
                            {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.PREJUIZO?.OPERACOES?.map(
                              (prejuizo, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {prejuizo.DESCRICAO}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    R$ {prejuizo.VALOR}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {prejuizo.PERCENTUAL}%
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <FaRegCheckCircle fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
              <div className="mt-10">
                <TitleStripe className="mb-5">Operações</TitleStripe>
                {data?.CREDCADASTRAL?.RELATORIO_SCR?.OPERACOES?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto  rounded-lg">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-red-300">
                            Modalidade
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Valor
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Percentual
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.CREDCADASTRAL?.RELATORIO_SCR?.OPERACOES?.map(
                            (operacoes, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                  {operacoes.MODALIDADE?.DESCRICAO}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  R$ {operacoes.TOTAL}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {operacoes.PERCENTUAL}%
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <FaRegCheckCircle fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>
            </Card>
          ) : (
            <Card>
              <div role="status" className="w-full animate-pulse">
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>
            </Card>
          )}
        </div>
      )}
    </>
  );
};

export default ScrBacenScoreQuery;
