import React from "react";
import moment from "moment";

const HeaderQuery = ({
  imgHeader = "/assets/logo_verde.png",
  size = "base",
  hideLogo = false,
}) => {
  return (
    <div className="flex justify-between items-center -mt-2 mb-3">
      <img
        src={imgHeader}
        className={`${size === "base" ? "h-20" : "h-8"} ${
          hideLogo ? "invisible" : ""
        }`}
      />
      <div>
        <span className="font-semibold mr-2">Data | Hora: </span>
        {moment(moment()).format("DD/MM/yyyy | HH:mm")}h
      </div>
    </div>
  );
};

export default HeaderQuery;
