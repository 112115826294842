import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Table,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { formatToReal, isValidCNPJ } from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { useApplication } from "../../contexts/ApplicationContext";
import { maskCNPJ } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import CardRed from "../common/CardRed";
import CardGreen from "../common/CardGreen";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";

const AcertaBvCNPJQuery = ({ queryId, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    queryId,
    data
  );

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    valor = maskCNPJ(valor);

    setInfo(valor);
  };

  const fetchDefineRisco = async (raw) => {
    return await axios(
      `${QUERIES.GET_ACERTA_BV}/${info.replace(/\D/g, "")}?type=cnpj`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCNPJ(unmaskedValue)) {
      setError("CNPJ inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorData("");
        const { data: dados } = await fetchDefineRisco();
        setData(dados.data.data.consultaCredito);
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Acerta BV CNPJ
      </div>

      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score",
          "Pendências financeiras",
          "Protestos",
          "Cheques sem fundos",
          "Cheques sustados",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-2 items-end">
            <div className="flex items-center gap-2">
              <div className="block">
                <Label htmlFor="info" value="CNPJ:" />
              </div>
              <TextInput
                id="info"
                type="text"
                required
                onChange={handleChange}
                maxLength="18"
                value={info}
              />
            </div>
            <Button
              className="shadow-lg primary flex items-center justify-center"
              type="submit"
              isProcessing={isLoading}
              processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
              style={{ height: "43px" }}
            >
              <FaSearch />
            </Button>
            {!errorData && data && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </CardQueryDescription>

      {errorData ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`${data || isLoading ? "block" : "hidden"}`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="mt-2">
                  <HeaderQuery />
                  <div className="title-syncx">Quadro de avisos</div>
                  <div className="flex gap-2 flex-wrap">
                    {+data?.resumoConsulta?.pendenciasFinanceiras
                      ?.quantidadeTotal > 0 ? (
                      <CardRed
                        title="Pendências financeiras:"
                        message={`${data?.resumoConsulta?.pendenciasFinanceiras?.quantidadeTotal} ocorrências`}
                      />
                    ) : (
                      <CardGreen
                        title="Pendências financeiras:"
                        message="Nada consta"
                      />
                    )}

                    {+data?.resumoConsulta?.protestos?.quantidadeTotal > 0 ? (
                      <CardRed
                        title="Protestos:"
                        message={`${+data?.resumoConsulta?.protestos
                          ?.quantidadeTotal} ocorrências`}
                      />
                    ) : (
                      <CardGreen title="Protestos:" message="Nada consta" />
                    )}

                    {+data?.resumoConsulta?.chequesSemFundo?.quantidadeTotal >
                    0 ? (
                      <CardRed
                        title="Cheques sem fundos:"
                        message={`${data?.resumoConsulta?.chequesSemFundo?.quantidadeTotal} ocorrências`}
                      />
                    ) : (
                      <CardGreen
                        title="Cheques sem fundos:"
                        message="Nada consta"
                      />
                    )}

                    {+data?.resumoConsulta?.chequesSustados?.quantidadeTotal >
                    0 ? (
                      <CardRed
                        title="Cheques sustados:"
                        message={`${+data?.resumoConsulta?.chequesSustados
                          ?.quantidadeTotal} ocorrências`}
                      />
                    ) : (
                      <CardGreen
                        title="Cheques sustados:"
                        message="Nada consta"
                      />
                    )}
                  </div>
                  <TitleStripe>Dados Cadastrais</TitleStripe>
                  <div className="flex flex-col md:flex-row gap-3 md:gap-20 text-sm">
                    <div>
                      <div className="mb-3 flex gap-2 items-center">
                        <span className="font-semibold">Situação: </span>
                        <Badge
                          className="w-fit"
                          color={
                            data?.dadosCadastrais?.situacao === "Ativo"
                              ? "success"
                              : "gray"
                          }
                        >
                          {data?.dadosCadastrais?.situacao}
                        </Badge>
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Razão Social: </span>
                        {data?.dadosCadastrais?.razaoSocial}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nome fantasia: </span>
                        {data?.dadosCadastrais?.nomeFantasia}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CNPJ: </span>
                        {data?.dadosCadastrais?.cnpj}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Fundação: </span>
                        {data?.dadosCadastrais?.dataFundacao}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CNAE Principal: </span>
                        {data?.dadosCadastrais?.ramoAtividade}
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Inscr. Estadual/UF:{" "}
                        </span>
                        {data?.dadosCadastrais?.inscricaoEstadual}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Natureza jurídica:{" "}
                        </span>
                        {data?.dadosCadastrais?.naturezaJuridica}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Endereço: </span>
                        {data?.dadosCadastrais?.endereco}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Bairro: </span>
                        {data?.dadosCadastrais?.bairro}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Cidade/UF: </span>
                        {data?.dadosCadastrais?.cidade}/
                        {data?.dadosCadastrais?.uf}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CEP: </span>
                        {data?.dadosCadastrais?.cep}
                      </div>
                    </div>
                  </div>
                  <div>
                    <TitleStripe>Score de risco</TitleStripe>
                  </div>
                  <div className="flex flex-col gap-2 md:flex-row items-center">
                    <div className="w-full md:w-3/5 p-4">
                      <ReactApexChart
                        options={{
                          chart: {
                            type: "radialBar",
                            offsetY: -20,
                            sparkline: {
                              enabled: true,
                            },
                          },
                          plotOptions: {
                            radialBar: {
                              startAngle: -90,
                              endAngle: 90,
                              track: {
                                background: "#e7e7e7",
                                strokeWidth: "97%",
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                  enabled: true,
                                  top: 2,
                                  left: 0,
                                  color: "#999",
                                  opacity: 1,
                                  blur: 2,
                                },
                              },
                              dataLabels: {
                                name: {
                                  show: false,
                                },
                                value: {
                                  offsetY: -2,
                                  fontSize: "22px",
                                  formatter: function (val) {
                                    return val * 10;
                                  },
                                },
                              },
                            },
                          },
                          grid: {
                            padding: {
                              top: -10,
                            },
                          },
                          fill: {
                            type: "solid",
                            gradient: {
                              shade: "light",
                              shadeIntensity: 0.4,
                              inverseColors: false,
                              opacityFrom: 1,
                              opacityTo: 1,
                              stops: [0, 50, 53, 91],
                            },
                            colors: (() => {
                              const score = +data?.score?.score;
                              if (score >= 800) {
                                return "#01B000";
                              } else if (score >= 600) {
                                return "#8DED01";
                              } else if (score >= 400) {
                                return "#FDF700";
                              } else if (score >= 200) {
                                return "#FFBC00";
                              } else {
                                return "#E54B00";
                              }
                            })(),
                          },
                        }}
                        series={[+data?.score?.score / 10]}
                        type="radialBar"
                      />
                    </div>
                    <div className="w-full">
                      <div className="mb-2">
                        <Alert
                          withBorderAccent
                          color="warning"
                          className="w-fit mb-2"
                        >
                          Probabilidade de indadimplência ={" "}
                          {data?.score?.probabilidade}
                        </Alert>{" "}
                        {data?.score?.mensagem}
                      </div>
                    </div>
                  </div>
                  <div className="border border-gray-300 p-2 text-sm">
                    <span className="font-semibold">Importante:</span> a decisão
                    de aprovação ou não do crédito é de exclusiva
                    responsabilidade do concedente. as informações prestadas
                    pelo provedor tem como objevo subsidiar essas decisões e, em
                    hipótese alguma devem ser ulizadas como justificativa, pelo
                    concedente do crédito, para a tomada da referida decisão.
                  </div>
                  <div>
                    <TitleStripe>Pendências financeiras</TitleStripe>

                    {data?.pendenciasFinanceiras?.length > 0 ? (
                      <>
                        <div className="flex flex-col md:flex-row gap-3 md:gap-6 mt-4">
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold">Quantidade: </span>
                            <Badge color="failure" className="w-fit">
                              {
                                data?.resumoConsulta?.pendenciasFinanceiras
                                  ?.quantidadeTotal
                              }
                            </Badge>
                          </div>
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold">
                              Último vencimento:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              -
                            </Badge>
                          </div>
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold">Valor Total: </span>
                            <Badge color="failure" className="w-fit">
                              R${" "}
                              {
                                data?.resumoConsulta?.pendenciasFinanceiras
                                  ?.valorTotal
                              }
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Vencimento
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Informante
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Tipo:
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Cidade:
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Documento origem:
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {data?.pendenciasFinanceiras?.map(
                                (ocorrencia, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {ocorrencia.data || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {ocorrencia.informante || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      R$ {ocorrencia.valor || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {ocorrencia.tipo || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {ocorrencia.cidade || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {ocorrencia.documento_origem || "-"}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>
                  <div>
                    <TitleStripe className="mb-5">Protestos</TitleStripe>
                    {data?.protestos?.length > 0 ? (
                      <>
                        <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure">
                              {data?.resumoConsulta?.protestos?.quantidadeTotal}
                            </Badge>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-red-700">
                              Valor Total:{" "}
                            </span>
                            <Badge color="failure">
                              R$ {data?.resumoConsulta?.protestos?.valorTotal}
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Cartório
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Cidade
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Estado
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {data?.protestos?.map((protesto, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className=" font-medium text-gray-900 dark:text-white text-center">
                                    {protesto.data}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    R$ {protesto.valor}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {protesto.cartorio}º cartório
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {protesto.cidade}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {protesto.estado}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>
                  <div>
                    <TitleStripe className="mb-5">
                      Cheques sem fundos
                    </TitleStripe>

                    {data?.chequesSemFundo?.length > 0 ? (
                      <>
                        <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure">
                              {
                                data?.resumoConsulta?.chequesSemFundo
                                  ?.quantidadeTotal
                              }
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Agência
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Nome do banco
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Quantidade
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Motivo
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {data?.chequesSemFundo.map((cheque, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {cheque.data}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.agencia}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.banco}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.quantidade || "-"}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.motivo}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>
                  <div>
                    <TitleStripe className="mb-5">Cheques sustados</TitleStripe>
                    {data?.chequesSustados?.length > 0 ? (
                      <>
                        <div className="flex flex-col md:flex-row gap-3 md:gap-6 flex-wrap">
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure">
                              {
                                data?.resumoConsulta?.chequesSustados
                                  ?.quantidadeTotal
                              }
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Banco
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Agência
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Cheque
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {data?.chequesSustados.map((cheque, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {cheque.data}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.banco}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.agencia}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.cheques}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Histórico de consultas
                    </TitleStripe>

                    {data?.historicoConsultas?.length > 0 ? (
                      <>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Empresa
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {data?.historicoConsultas.map(
                                (consult, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                      {consult.data}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {consult.empresa}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>
                </Card>
              </>
            ) : (
              <Card>
                <div role="status" className="w-full animate-pulse">
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                </div>
              </Card>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AcertaBvCNPJQuery;
