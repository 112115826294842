import { useQuery, useQueryClient } from "react-query";
import { CATEGORIES, USERS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { Badge, Button, Card, Pagination } from "flowbite-react";
import { useApplication } from "../../contexts/ApplicationContext";
import { useState } from "react";
import { Icon } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import CardLoading from "../CardLoading";
import { getFirstLettersName } from "../../utils/utils";
import { TbLockSearch } from "react-icons/tb";
import Drawer from "../Drawer";
import UserQueriesForm from "../forms/UserQueriesForm";
import UserForm from "../forms/UserForm";

const UsersTable = () => {
  const { user } = useApplication();
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();
  const [isDrawerPermissionQueries, setIsDrawerPermissionQueries] =
    useState(false);
  const [isDrawerOpenUser, setIsDrawerOpenUser] = useState(false);
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [totalPages, setTotalPages] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [typeDrawer, setTypeDrawer] = useState("CREATE");
  const onPageChange = (page) => setCurrentPage(page);
  const {
    isLoading,
    error,
    data: users,
  } = useQuery(["users", currentPage], async () => {
    const { data: items } = await axios.get(
      `${USERS.GET_USERS}?page=${currentPage}`
    );
    setTotalPages(items.data.totalPages || 1);
    return items.data.data;
  });

  const {
    isLoadingCategories,
    error: errorCategories,
    data: categories,
  } = useQuery(["categories", currentPage], async () => {
    const { data } = await axios.get(
      `${CATEGORIES.GET_ALL}?page=${currentPage}`
    );

    return data.data.data;
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prevProfessional) => ({
      ...prevProfessional,
      [name]: value,
    }));
  };

  const handleAddProfessional = () => {
    setSelectedUser(null);
    setTypeDrawer("CREATE");
    setIsDrawerOpenUser((prevState) => !prevState);
  };

  const handleEditUserQueriesPermission = (user) => {
    setSelectedUser(user);
    setIsDrawerPermissionQueries((prevState) => !prevState);
  };

  const handleEditUser = (user) => {
    setTypeDrawer("EDIT");
    setSelectedUser(user);
    setIsDrawerOpenUser((prevState) => !prevState);
  };

  const handleDeleteProfessional = (professional) => {
    setSelectedUser(professional);
    setOpenModalDelete(true);
  };

  const deleteProfessional = async () => {
    try {
      setIsLoadingDelete(true);
      await axios.delete(`${USERS.GET_USER}/${selectedUser.id}`);

      queryClient.invalidateQueries(["users", currentPage]);
      setOpenModalDelete(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <>
      <div className="flex justify-end mb-3 mt-2">
        <Button
          className="primary ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-lg md:shadow-md h-14 md:h-auto w-14 md:w-auto flex md:block justify-center items-center"
          variant="contained"
          disabled={isLoading ? true : false}
          onClick={handleAddProfessional}
        >
          <IoMdAdd className="block md:hidden text-2xl" />

          <span className="hidden md:block">Adicionar usuário</span>
        </Button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 mt-4">
        {!isLoading
          ? users.map((user, index) => (
              <Card key={index} className="relative">
                <div className="flex gap-3 items-center">
                  {!user?.imageUrl ? (
                    <div
                      className="flex items-center justify-center"
                      style={{
                        height: "40px",
                        width: "40px",
                        fontSize: "18px",
                        color: "white",
                        borderRadius: "50%",
                        minWidth: "40px",
                        background: `var(--primary)`,
                      }}
                    >
                      {getFirstLettersName(user?.name)}
                    </div>
                  ) : (
                    <div>
                      <img
                        src={user?.imageUrl}
                        className="rounded-full"
                        style={{
                          objectFit: "cover",
                          width: "40px",
                          minWidth: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  )}
                  <div className="flex gap-2 items-center">
                    <h2>{user?.name}</h2>
                    {user?.isActive ? (
                      <Badge color="green" style={{ fontSize: "10px" }}>
                        Ativo
                      </Badge>
                    ) : (
                      <Badge color="pink" style={{ fontSize: "10px" }}>
                        Inativo
                      </Badge>
                    )}
                    {user?.permissionId === "ADMIN" && (
                      <Badge color="gray" style={{ fontSize: "10px" }}>
                        Admin
                      </Badge>
                    )}
                  </div>
                </div>
                <div className="flex gap-1 text-gray-500 text-xs">
                  <Icon fontSize="small">password</Icon>
                  <span className="text-sm truncate">
                    Cód. Acesso:{" "}
                    <Badge className="w-fit inline-block" color="green">
                      {user.id}
                    </Badge>
                  </span>
                </div>
                <div className="flex gap-1 text-gray-500 mr-20">
                  <Icon fontSize="small">phone</Icon>
                  <span className="text-sm truncate mr-12">
                    Celular: {user.phoneNumber}
                  </span>
                </div>
                <div className="flex absolute bottom-5 right-3 gap-8">
                  {!["ADMIN", "POWER_USER"].includes(user?.permissionId) && (
                    <TbLockSearch
                      fontSize="24"
                      onClick={() => handleEditUserQueriesPermission(user)}
                      className="cursor-pointer"
                    />
                  )}

                  <Icon
                    fontSize="small"
                    className="color-primary cursor-pointer"
                    onClick={() => handleEditUser(user)}
                  >
                    edit
                  </Icon>
                  <Icon
                    fontSize="small"
                    className="text-gray-500 cursor-pointer"
                    // onClick={() => handleDeleteProfessional(professional)}
                  >
                    delete
                  </Icon>
                </div>
              </Card>
            ))
          : Array.from({ length: 12 }).map((_, index) => (
              <CardLoading key={index} />
            ))}
      </div>
      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}

      <Drawer
        title={`${
          typeDrawer === "CREATE"
            ? "Criar usuário"
            : `Editar usuário: ${selectedUser?.name?.split(" ")[0]}`
        }`}
        onClose={setIsDrawerOpenUser}
        isOpen={isDrawerOpenUser}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <UserForm
          user={selectedUser}
          onClose={setIsDrawerOpenUser}
          isOpen={isDrawerOpenUser}
          type={typeDrawer}
          fetchUsers={() => {
            queryClient.invalidateQueries(["users"]);
          }}
        />
      </Drawer>

      <Drawer
        title={`Permissões de consultas para ${
          selectedUser?.name?.split(" ")[0]
        }`}
        onClose={setIsDrawerPermissionQueries}
        isOpen={isDrawerPermissionQueries}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <UserQueriesForm
          user={selectedUser}
          categories={categories}
          onClose={setIsDrawerPermissionQueries}
          isOpen={isDrawerPermissionQueries}
        />
      </Drawer>
    </>
  );
};

export default UsersTable;
