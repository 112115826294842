import React, { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import { USERS } from "../../config/apiConfig";
import { useQuery } from "react-query";
import axios from "../../config/axiosInstance";
import { AiOutlineFileSearch } from "react-icons/ai";
import DrawerLoading from "../DrawerLoading";
import ForbiddenQueriesPermission from "../common/ForbiddenQueriesPermission";
import ConsultaSimplesQuery from "../queries/ConsultaSimplesQuery";
import SerasaBasicoQuery from "../queries/SerasaBasicoQuery";
import CrednetLightQuery from "../queries/CrednetLightQuery";
import SCPCNet2Query from "../queries/SCPCNet2Query";
import SpcCPFQuery2 from "../queries/SpcCPFQuery2";
import { useApplication } from "../../contexts/ApplicationContext";
import SpcCPFQueryKL from "../queries/kl-mesquita/SpcCPFQueryKL";
import ConsultaSimplesQueryKL from "../queries/kl-mesquita/ConsultaSimplesQueryKL";
import SerasaBasicoQueryKL from "../queries/kl-mesquita/SerasaBasicoQueryKL";
import CrednetLightQueryKL from "../queries/kl-mesquita/CrednetLightQueryKL";
import SCPCNetQueryKL from "../queries/kl-mesquita/SCPCNetQueryKL";

const QueriesKLMesquitaTable = () => {
  const { user } = useApplication();
  const [selectedQuery, setSelectedQuery] = useState("");
  const [selectedPrice, setSelectedPrice] = useState(0);

  const {
    isLoading,
    error,
    data: userQueryPermissions,
  } = useQuery(["userQueryPermissions"], async () => {
    const { data: items } = await axios.get(
      `${USERS.GET_USER_QUERY_PERMISSIONS}`
    );

    return items.data.filter((query) => query.categoryId === 5);
  });

  useEffect(() => {
    if (selectedQuery) {
      const matchingQuery = userQueryPermissions.find(
        (query) => query.id === selectedQuery
      );

      setSelectedPrice(matchingQuery.price);
    }
  }, [selectedQuery]);

  const queries = [
    {
      id: 18,
      name: "SPC CPF",
      component: <SpcCPFQueryKL queryId={18} selectedPrice={selectedPrice} />,
    },
    {
      id: 19,
      name: "Consulta Simples",
      component: (
        <ConsultaSimplesQueryKL queryId={19} selectedPrice={selectedPrice} />
      ),
    },
    {
      id: 20,
      name: "Serasa Relatório Básico",
      component: (
        <SerasaBasicoQueryKL queryId={20} selectedPrice={selectedPrice} />
      ),
    },
    {
      id: 21,
      name: "Serasa sem Score | Crednet",
      component: (
        <CrednetLightQueryKL queryId={21} selectedPrice={selectedPrice} />
      ),
    },
    {
      id: 22,
      name: "SCPC Net 2",
      component: <SCPCNetQueryKL queryId={22} selectedPrice={selectedPrice} />,
    },
  ];

  return (
    <div className="w-100 flex flex-col lg:flex-row gap-2 relative">
      {isLoading && <DrawerLoading />}
      {userQueryPermissions?.length > 0 ? (
        <>
          <div className="w-full lg:w-2/6 p-4 flex flex-col gap-3">
            <h2 className="title-syncx text-lg">
              Consultas{" "}
              {user?.permissionId === "POWER_USER" ? "KL Mesquita" : "5"}
            </h2>
            {userQueryPermissions?.map((query, index) => (
              <Card
                key={index}
                className={`card-query ${
                  selectedQuery === query.id && "active"
                }`}
                onClick={() => setSelectedQuery(query.id)}
              >
                {query.name}
              </Card>
            ))}
          </div>
          {selectedQuery ? (
            <div className={`w-full p-4`}>
              {queries.find((query) => query.id === selectedQuery)?.component}
            </div>
          ) : (
            <div className="mt-14 w-full text-center py-10 border-dashed border-gray-300 border-2 rounded font-semibold text-gray-500 flex items-center justify-center min-h-60 flex gap-1 flex-col">
              <AiOutlineFileSearch fontSize={30} />
              <span>Escolha uma opção ao lado para realizar uma consulta.</span>
            </div>
          )}
        </>
      ) : (
        <ForbiddenQueriesPermission />
      )}
    </div>
  );
};

export default QueriesKLMesquitaTable;
