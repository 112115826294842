let BASE_URL = "";

if (process.env.NODE_ENV === "development") {
  BASE_URL = "http://localhost:3000";
} else if (process.env.NODE_ENV === "production") {
  BASE_URL = "https://centraldivino.com.br";
}

BASE_URL += "/api";

const LOGIN = {
  POST_LOGIN: BASE_URL + "/login",
};

const API = {
  GET_TOKEN: "/get-api-rest-token",
};

const CATEGORIES = {
  GET_ALL: "/categories",
};

const COMPANY = {
  GET_PERMISSION_QUERIES: "/company/permission/queries",
};

const USERS = {
  GET_USERS: "/users",
  GET_USER: "/user",
  GET_USER_CURRENT: "/user-current",
  GET_USER_QUERY_PERMISSIONS: "/user-query/permission",
};

const ADMIN = {
  GET_CUSTOMERS: "/admin/customers",
  GET_COMPANY_QUERIES: "/admin/company/queries",
  GET_QUERIES: "/admin/queries",
  NEW_CUSTOMER: "/admin/customer/new",
  GET_COMPANY_BY_INFO: "/admin/company/info",
  GET_COMPANY: "/admin/company",
  GET_COMPANY_QUERIES_HISTORY: "/admin/queries/company",
  GET_COMPANY_QUERIES_HISTORY_SIMPLIFIED: "/admin/queries/company/simplified",
  GET_PAYMENTS: "/admin/payments",
  GET_QUERY: "/admin/query",
};

const QUERIES = {
  GET_ACERTA_BV: "/query/acerta-bv",
  GET_SPC_CPF: "/query/spc-cpf",
  GET_SPC_CPF_2: "/query/spc-cpf-2",
  GET_SPC_CPF_4: "/query/spc-cpf-4",
  GET_SPC_CPF_KL: "/query/spc-cpf-kl",
  GET_SERASA_SCORE: "/query/serasa-score",
  GET_ACERTA_BV_CPF_SEM_PROTESTO: "/query/acerta-bv-cpf-sem-protesto",
  GET_SCPC_NET: "/query/scpc-net",
  GET_SCPC_NET_2: "/query/scpc-net-2",
  GET_SCPC_NET_KL: "/query/scpc-net-kl",
  GET_SCR_BACEN_SCORE: "/query/scr-bacen-score",
  GET_SERASA_BASICO: "/query/serasa-basico",
  GET_SERASA_BASICO_4: "/query/serasa-basico-4",
  GET_SERASA_BASICO_KL: "/query/serasa-basico-kl",
  GET_CONSULTA_SIMPLES_4: "/query/consulta-simples-4",
  GET_CONSULTA_SIMPLES_KL: "/query/consulta-simples-kl",
  GET_CREDNET_LIGHT: "/query/crednet-light",
  GET_CREDNET_LIGHT_4: "/query/crednet-light-4",
  GET_CREDNET_LIGHT_KL: "/query/crednet-light-kl",
  GET_LAST_QUERIES: "/query/last",
  GET_LAST_7_DAYS_COUNT_QUERIES: "/query/total/last/7",
  GET_QUERIES_TOTAL: "/query/total",
  GET_QUERIES_LIST: "/queries",
  UPLOAD_PDF: "/query/upload/pdf",
  DOWNLOAD_PDF: "/query/download/pdf",
  GET_DASHBOARD_INFOS: "/query/dashboard",
  GET_SERASA_BASICO_CADASTRAL: "/query/serasa-basico-cadastral",
  GET_CREDNET_LIGHT_CADASTRAL: "/query/crednet-light-cadastral",
  GET_SCPC_NET_CADASTRAL: "/query/scpc-net-cadastral",
};

const AUDITS = {
  GET_AUDITS: "/audits",
};

const AVATAR = {
  UPLOAD: "/avatar/upload",
};

const PAYMENTS = {
  CREATE: "/payment/create",
  GET_ALL: "/invoices",
  ADD_CREDIT: "/payment/confirm",
  ADD_CREDIT_MANUALLY: "/payment/manually",
};

const REPORTS = {
  GET_COMPANY_QUERIES_HISTORY: "/report/queries/company",
  GET_COMPANY_QUERIES_HISTORY_SIMPLIFIED: "/report/queries/company/simplified",
};

export {
  API,
  LOGIN,
  CATEGORIES,
  COMPANY,
  USERS,
  ADMIN,
  QUERIES,
  AUDITS,
  AVATAR,
  PAYMENTS,
  REPORTS,
  BASE_URL,
};
