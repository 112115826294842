import domtoimage from "dom-to-image";
import { PDFDocument } from "pdf-lib";

export const handleDownloadPdf = async (element) => {
  try {
    // Salva o estilo original
    const originalStyle = element.style.cssText;

    // Define estilo de desktop temporário
    element.style.width = "1050px";
    element.style.height = "auto";
    element.style.transform = "scale(1)"; // Garante que não há zoom aplicado

    // Captura a imagem do elemento
    const dataUrl = await domtoimage.toPng(element);

    // Restaura o estilo original
    element.style.cssText = originalStyle;

    // Carrega a imagem em um elemento Image
    const img = new Image();
    img.src = dataUrl;

    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    // Cria um documento PDF
    const pdfDoc = await PDFDocument.create();
    const pdfWidth = 595.28; // Largura da página A4 em pontos (210mm)
    const pdfHeight = 841.89; // Altura da página A4 em pontos (297mm)

    const imgWidth = img.width;
    const imgHeight = img.height;
    const scaleFactor = pdfWidth / imgWidth; // Fator de escala para ajustar a largura da imagem ao PDF
    const segmentHeight = pdfHeight / scaleFactor;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = imgWidth;
    canvas.height = segmentHeight;

    const totalSegments = Math.ceil(imgHeight / segmentHeight);

    for (let i = 0; i < totalSegments; i++) {
      const page = pdfDoc.addPage([pdfWidth, pdfHeight]);

      // Define a posição vertical do segmento atual
      const yOffset = segmentHeight * i;

      // Limpa o canvas e desenha o segmento correspondente
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        img,
        0,
        yOffset,
        imgWidth,
        segmentHeight,
        0,
        0,
        imgWidth,
        segmentHeight
      );

      const segmentDataUrl = canvas.toDataURL("image/png");
      const pdfImage = await pdfDoc.embedPng(segmentDataUrl);

      page.drawImage(pdfImage, {
        x: 0,
        y: 0,
        width: pdfWidth,
        height: pdfHeight,
      });
    }

    const modifiedPdfBytes = await pdfDoc.save();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    return blob;
  } catch (error) {
    console.error("Erro ao gerar o PDF:", error);
  }
};
