import React from "react";
import { Badge, Card, Table } from "flowbite-react";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";

const DocAcertaEssencial = () => {
  return (
    <Card className={`w-full mt-3`}>
      <div className="mb-4">
        <h2 className="title-syncx">
          Documentação da API: Acerta Essencial CPF
        </h2>
        <br /> Esta documentação descreve como integrar-se à API utilizando um
        método <b>GET</b> com autenticação baseada em token. <br />
        Siga as instruções abaixo para enviar uma solicitação autenticada e
        entender a resposta da API.
        <br />
        <br />
        Endpoint URL:{" "}
        <Badge className="inline" color="indigo">
          https://app.syncx-api.com.br/api/acerta-essencial/:CPF
        </Badge>{" "}
        <br />
        Método:{" "}
        <Badge className="inline" color="indigo">
          GET
        </Badge>
        <br />
        Autenticação:{" "}
        <Badge className="inline" color="indigo">
          Bearer Token
        </Badge>
        <br />
        <div className="mt-4">
          <h2 className="title-syncx">Parâmetros da requisição</h2>
          <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Parâmetro
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Obrigatório
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Observações
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    CPF
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Texto
                  </Table.Cell>
                  <Table.Cell className="text-center text-red-500">
                    Sim
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Informar o CNPJ a ser consultado no parâmetro da URL.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
        <br />
        Obs: Envie o token de autenticação no cabeçalho da requisição.
        <div className="mt-4">
          <h2 className="title-syncx">Parâmetros da resposta</h2>
          <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Chave
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Observações
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    resumo_ocorrencias_de_debitos
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Objeto
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Informar o CNPJ a ser consultado no parâmetro da URL.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
      <CodeBlock
        text={`essencial:{
          "resumo_ocorrencias_de_debitos": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "108",
              "registro": "N"
          },
          "resumoConsultas_anteriores_90_dias": {
              "tamanhoRegistro": "49",
              "tipoRegistro": "111",
              "registro": "S",
              "total": "00005",
              "ano_1": "2024",
              "mes_1": "08",
              "total_1": "0002",
              "ano_2": "2024",
              "mes_2": "07",
              "total_2": "0000",
              "ano_3": "2024",
              "mes_3": "06",
              "total_3": "0002",
              "ano_4": "2024",
              "mes_4": "05",
              "total_4": "0001"
          },
          "informacoes_complementares": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "123",
              "registro": "N"
          },
          "debitos": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "124",
              "registro": "N"
          },
          "consultas_anteriores": [
              {
                  "tamanhoRegistro": "66",
                  "tipoRegistro": "126",
                  "registro": "S",
                  "tipoOcorrencia": "OU",
                  "data": "08/08/2024",
                  "moeda": "",
                  "valor": "0,00",
                  "informante": "EQUIFAX DO BRASIL LTDA",
                  "produto": "S"
              },
              {
                  "tamanhoRegistro": "66",
                  "tipoRegistro": "126",
                  "registro": "S",
                  "tipoOcorrencia": "CH",
                  "data": "13/06/2024",
                  "moeda": "",
                  "valor": "0,00",
                  "informante": "EQUIFAX DO BRASIL LTDA",
                  "produto": "S"
              },
              {
                  "tamanhoRegistro": "66",
                  "tipoRegistro": "126",
                  "registro": "S",
                  "tipoOcorrencia": "CH",
                  "data": "12/06/2024",
                  "moeda": "",
                  "valor": "0,00",
                  "informante": "EQUIFAX DO BRASIL LTDA",
                  "produto": "S"
              },
              {
                  "tamanhoRegistro": "66",
                  "tipoRegistro": "126",
                  "registro": "S",
                  "tipoOcorrencia": "CD",
                  "data": "02/08/2024",
                  "moeda": "",
                  "valor": "0,00",
                  "informante": "EQUIFAX DO BRASIL LTDA",
                  "produto": "N"
              },
              {
                  "tamanhoRegistro": "66",
                  "tipoRegistro": "126",
                  "registro": "S",
                  "tipoOcorrencia": "CD",
                  "data": "24/05/2024",
                  "moeda": "",
                  "valor": "0,00",
                  "informante": "EQUIFAX DO BRASIL LTDA",
                  "produto": "N"
              }
          ],
          "titulos_protestados": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "142",
              "registro": "N"
          },
          "resumo_titulos_protestados": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "146",
              "registro": "N"
          },
          "resumo_devolucoes_informadas_pelo_ccf": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "214",
              "registro": "N"
          },
          "nome_documentos": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "241",
              "registro": "N"
          },
          "relacao_devolucoes_informadas_pelo_ccf": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "242",
              "registro": "N"
          },
          "devolucoes_informadas_pelo_usuario": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "244",
              "registro": "N"
          },
          "cheques_sustados_pelo_motivo_21": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "245",
              "registro": "N"
          },
          "resumo_devolucoes_informada_pelo_usuario": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "268",
              "registro": "N"
          },
          "identificacao": {
              "tamanhoRegistro": "316",
              "tipoRegistro": "500",
              "registro": "S",
              "documento": "01061677400",
              "nome": "\"\"",
              "nomeMae": "NOME DA MAE TESTE",
              "dataNascimento": "02/09/1981",
              "numeroRG": "19459797",
              "orgaoEmissor": "",
              "unidadeFedarativaRG": "",
              "dataEmissaoRG": "-",
              "sexoConsultado": "1",
              "cidadeNascimento": "BRASILEIRO",
              "estadoCivil": "2",
              "numeroDependentes": "0",
              "grauInstrucao": "10",
              "situacaoReceita": "1",
              "dataAtualizacao": "10/05/2020",
              "regiaoCpf": "4",
              "tituloEleitor": "0000000000000",
              "obito": ""
          },
          "localizacao": "",
          "score_classificacao_varios_modelos": [
              {
                  "tamanhoRegistro": "450",
                  "tipoRegistro": "601",
                  "registro": "S",
                  "tipoScore": "1",
                  "score": "167",
                  "planoExecucao": "N",
                  "modeloPlano": "63",
                  "nomePlano": "",
                  "modeloScore": "63",
                  "nomeScore": "NOVO RISCO 6 MESES",
                  "classificacaoNumerica": "14",
                  "classificacaoAlfabetica": "D",
                  "probabilidade": "09530",
                  "texto": "De cada 100 pessoas classificadas nesta classe de score, é provável que 95 apresentem débitos no mercado nos próximos 6 meses.",
                  "codigoNaturezaModelo": "101",
                  "descricaoNatureza": "CREDITO",
                  "texto2": ""
              },
              {
                  "tamanhoRegistro": "450",
                  "tipoRegistro": "601",
                  "registro": "S",
                  "tipoScore": "1",
                  "score": "4",
                  "planoExecucao": "N",
                  "modeloPlano": "41",
                  "nomePlano": "",
                  "modeloScore": "41",
                  "nomeScore": "RENDA PRESUM FAIXA",
                  "classificacaoNumerica": "04",
                  "classificacaoAlfabetica": "",
                  "probabilidade": "00000",
                  "texto": "De R$ 1.401 até R$ 2.000",
                  "codigoNaturezaModelo": "103",
                  "descricaoNatureza": "RENDA PRESUMIDA",
                  "texto2": "Renda estimada a partir de informações comportamentais e cadastrais do consumidor."
              }
          ],
          "decisao": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "603",
              "registro": "N"
          },
          "mensagem_registro": {
              "tamanhoRegistro": "004",
              "tipoRegistro": "940",
              "registro": "N"
          }
      }`}
        language={"json"}
        showLineNumbers={true}
        wrapLines
        theme={dracula}
      />
    </Card>
  );
};

export default DocAcertaEssencial;
