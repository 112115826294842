import { Alert, Badge, Button, Modal } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { MdOutlinePayments } from "react-icons/md";
import Input from "../inputs/Input";
import { useForm } from "react-hook-form";
import axios from "../../config/axiosInstance";
import { PAYMENTS } from "../../config/apiConfig";
import { useEffect, useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import { formatToReal } from "../../utils/utils";

const ModalAddCredit = ({ isOpen, setOpenModal }) => {
  const [qrCode, setQrCode] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [errorPayment, setErrorPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm();

  const handlePixPayment = async (data) => {
    try {
      if (data.valuePix < 5) {
        setError("valuePix", {
          type: "manual",
          message: "O valor mínimo permitido é 5 reais",
        });
        return;
      }
      setErrorPayment(null);
      setIsLoading(true);
      const dataPix = await axios.get(
        `${PAYMENTS.CREATE}?value=${data.valuePix}&type=PIX`
      );

      setQrCode(dataPix.data.data.encodedImage);
      setPaymentLink(dataPix.data.data.payload);
    } catch (error) {
      setErrorPayment(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reset();
    setErrorPayment(null);
    setPaymentLink("");
    setQrCode("");
  }, [isOpen]);

  const calcTax = () => {
    return +watch("valuePix") - 1.99;
  };

  return (
    <>
      <Modal show={isOpen} size="md" onClose={() => setOpenModal(false)} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <div className="flex gap-2 justify-center items-center mb-5">
              <MdOutlinePayments className="h-8 w-8 text-gray-400 dark:text-gray-200" />
              <h3 className="text-lg font-normal text-gray-500 dark:text-gray-400">
                Adicionar créditos
              </h3>
            </div>
            {!qrCode && (
              <form onSubmit={handleSubmit(handlePixPayment)}>
                <Input
                  type="number"
                  step="0.01"
                  name="valuePix"
                  register={register}
                  label="Valor"
                  required={true}
                  min={5}
                />
                {errors.valuePix && (
                  <Alert
                    color="failure"
                    className="mt-1 p-1 flex justify-center items-center text-xs"
                  >
                    Valor mínimo de 5 reais
                  </Alert>
                )}
                <Button
                  type="submit"
                  className="bg-primary mt-2 w-full"
                  processingSpinner={
                    <AiOutlineLoading className="h-6 w-6 animate-spin" />
                  }
                  isProcessing={isLoading}
                >
                  Gerar pagamento
                </Button>
              </form>
            )}
            {qrCode && (
              <div className="mt-4">
                <Alert className="mb-5">
                  Valor creditado será: <b>{formatToReal(calcTax())}</b>
                </Alert>
                <div>Pague com QR Code</div>
                <img
                  className="max-w-48 mx-auto"
                  src={`data:image/png;base64,${qrCode}`}
                />
                <Badge className="w-fit mx-auto mb-2">ou</Badge>
                <div className="mb-2">Pague com código PIX</div>
                <CopyBlock text={paymentLink} codeBlock theme={dracula} />
              </div>
            )}

            {errorPayment && (
              <Alert color="failure" className="mt-2">
                <span className="font-medium">Ops!</span> Ocorreu um erro ao
                tentar gerar uma cobrança por PIX. Tente novamente mais tarde!
              </Alert>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalAddCredit;
