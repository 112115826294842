import Input from "../components/inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { Alert, Button, Tabs } from "flowbite-react";
import useCEP from "../hooks/useCEP";
import { useForm } from "react-hook-form";
import { USERS } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { useEffect, useState } from "react";
import { useApplication } from "../contexts/ApplicationContext";
import { useQuery } from "react-query";
import CPFInput from "../components/inputs/CPFInput";
import PhoneInput from "../components/inputs/PhoneInput";
import SelectInput from "../components/inputs/SelectInput";
import { HiAdjustments, HiUserCircle } from "react-icons/hi";

const MyProfilePage = () => {
  const { user } = useApplication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const {
    isLoading,
    error,
    data: professional,
  } = useQuery(["professional", user], async () => {
    if (user?.id) {
      const { data: item } = await axios.get(`${USERS.GET_USER}/${user.id}`);
      return item.data;
    }
  });

  const {
    cep,
    setCep,
    address,
    loading: loadingCEP,
    error: errorCEP,
  } = useCEP();

  const onClickSearchCEP = (e) => {
    setCep(getValues("addressPostalCode"));
  };

  useEffect(() => {
    setValue("address", address?.logradouro);
    setValue("addressNeighborhood", address?.bairro);
    setValue("addressCity", address?.localidade);
  }, [address]);

  useEffect(() => {
    if (professional) {
      Object.keys(professional).forEach((key) => {
        setValue(key, professional[key]);
      });
    }
  }, [professional]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      delete data.password;
      await axios.put(`${USERS.GET_USER}/${user.id}`, data);
      setAlert({
        show: true,
        message: "Informações atualizadas com sucesso!",
        type: "success",
      });
    } catch (error) {
      setErrorSubmit(error);
      setAlert({
        show: true,
        message: "Erro ao tentar atualizar informações!",
        type: "failure",
      });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  const onSubmitChangePassword = async (data) => {
    try {
      setIsSubmitting(true);

      if (data.password !== data.password_confirm)
        throw new Error("Senhas divergentes");

      data = { id: data.id, password: data.password };

      await axios.put(`${USERS.GET_USER}/${user.id}`, data);
      setAlert({
        show: true,
        message: "Senha atualizada com sucesso!",
        type: "success",
      });
    } catch (error) {
      setErrorSubmit(error);
      setAlert({
        show: true,
        message: "Erro ao tentar atualizar senha!",
        type: "failure",
      });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  return (
    <div className="bg-white px-4 py-4 rounded-md shadow-md">
      <Tabs aria-label="Default tabs">
        <Tabs.Item active title="Meu Perfil" icon={HiUserCircle}>
          <form className="mb-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <Input
                type="text"
                label="Nome *"
                name="name"
                required={true}
                register={register}
                error={errors?.name && "Este campo é obrigatório"}
              />
            </div>
            <div className="mb-6 flex gap-2">
              <Input
                type="text"
                label="CEP"
                name="addressPostalCode"
                required={false}
                register={register}
                className="flex-1"
              />
              <Button
                onClick={onClickSearchCEP}
                className="flex align-center whitespace-nowrap primary"
                style={{ width: "220px", paddingTop: "4px" }}
                isProcessing={loadingCEP}
                processingSpinner={
                  <AiOutlineLoading className="h-6 w-6 animate-spin" />
                }
              >
                Buscar CEP
              </Button>
            </div>
            <div className="mb-6">
              <Input
                type="text"
                label="Endereço"
                name="address"
                required={false}
                register={register}
              />
            </div>
            <div className="mb-6">
              <div className="flex gap-4 flex-col md:flex-row">
                <Input
                  type="text"
                  label="Número"
                  name="addressNumber"
                  required={false}
                  register={register}
                  className="flex-1"
                />
                <Input
                  type="text"
                  label="Bairro"
                  name="addressNeighborhood"
                  required={false}
                  register={register}
                  className="flex-1"
                />
                <Input
                  type="text"
                  label="Cidade"
                  name="addressCity"
                  required={false}
                  register={register}
                  className="flex-1"
                />
              </div>
            </div>
            <div className="mb-6">
              <div className="flex flex-col md:flex-row gap-4">
                <Input
                  type="date"
                  label="Data de nascimento"
                  name="birthday"
                  required={false}
                  register={register}
                  className="flex-1"
                />
                <div className="w-full relative flex-1">
                  <select
                    id="gender"
                    name="gender"
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    {...register("gender")}
                  >
                    <option value="">Selecione o gênero</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                  </select>
                  <label
                    htmlFor="gender"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Sexo
                  </label>
                </div>
              </div>
            </div>

            <Button
              type="submit"
              className="w-full primary"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Atualizar perfil
            </Button>

            {alert.show && (
              <Alert className="mt-4" color={`${alert.type}`}>
                <span className="font-medium">{alert.message}</span>
              </Alert>
            )}
          </form>
        </Tabs.Item>
        <Tabs.Item title="Trocar senha" icon={HiAdjustments}>
          <form
            className="mb-10"
            onSubmit={handleSubmit(onSubmitChangePassword)}
          >
            <div className="mb-6">
              <Input
                type="password"
                label="Nova senha *"
                name="password"
                required={false}
                register={register}
              />
            </div>
            <div className="mb-6">
              <Input
                type="password"
                label="Confirmar senha *"
                name="password_confirm"
                required={false}
                register={register}
              />
            </div>
            <Button
              type="submit"
              className="w-full primary"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Atualizar senha
            </Button>
            {alert.show && (
              <Alert className="mt-4" color={`${alert.type}`}>
                <span className="font-medium">{alert.message}</span>
              </Alert>
            )}
          </form>
        </Tabs.Item>
      </Tabs>
    </div>
  );
};

export default MyProfilePage;
