import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Table,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { formatToReal, isValidCNPJ, isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import CardQueryDescription from "../common/CardQueryDescription";
import moment from "moment";
import QueryInput from "../inputs/QueryInput";

const CrednetLightQuery = ({ queryId, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    queryId,
    data
  );

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_CREDNET_LIGHT}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setDocument(info);
    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    } else {
      if (!isValidCNPJ(unmaskedValue)) {
        setError("CNPJ inválido!");
        isValid = false;
      }
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data.data);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const sumTotal = (arr, key) => {
    return arr?.reduce(
      (acc, curr) =>
        acc +
        parseFloat(
          curr[key]
            .replace("R$", "")
            .replace(/\./g, "")
            .replace(",", ".")
            .trim()
        ),
      0
    );
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Crednet Light
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Serasa",
          "Dados Cadastrais",
          "Participações societárias",
          "Pend. Internas",
          "Cheques sem fundo",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-2 items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF/CNPJ"
            />
            {!errorQuery && data && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
        </form>
      </CardQueryDescription>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`${data || isLoading ? "block" : "hidden"}`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="mt-2">
                  <div className="flex items-center justify-between mb-5">
                    <img className="w-40" src="/assets/logo_serasa.png" />
                  </div>

                  <div>
                    <div
                      className="font-semibold text-base md:text-2xl pb-1 border-b"
                      style={{ color: "#a7763c" }}
                    >
                      Crednet Light
                    </div>

                    <div className="mt-5 flex justify-end">
                      {moment().format("DD MMMM yyyy HH:mm:ss")}
                    </div>

                    <div className="overflow-scroll-x mt-1">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              CPF/CNPJ
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Status
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Banco
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Agência
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Conta
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Cheque
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              <div>{document}</div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div>
                                {" "}
                                {data
                                  ? data["Pendências REFIN/PEFIN"]?.length
                                  : 0}{" "}
                                anotações
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div></div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div></div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div></div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div></div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="mt-5">
                    <div
                      className="font-semibold text-base md:text-2xl pb-1 border-b"
                      style={{ color: "#a7763c" }}
                    >
                      Resumo da consulta
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              {document?.length === 14 ? "CPF" : "CNPJ"}
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              {document?.length === 14
                                ? "Nome"
                                : "Razão Social"}
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              {document?.length === 14
                                ? "Nome da mãe"
                                : "Natureza"}
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              {document?.length === 14
                                ? "Data de Nascimento"
                                : "Data de Fundação"}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              <div>{document}</div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div>
                                {document?.length === 14
                                  ? data?.cadastral?.NOME
                                  : data?.cadastral?.RAZAO_SOCIAL}
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div>
                                {document?.length === 14
                                  ? data?.cadastral?.NOME_MAE
                                  : data?.cadastral?.NATUREZA_DESCRICAO}
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div>
                                {document?.length === 14
                                  ? moment(
                                      data?.cadastral?.DATA_NASCIMENTO
                                    ).format("DD/MM/yyyy")
                                  : moment(
                                      data?.cadastral?.DATA_ABERTURA
                                    ).format("DD/MM/yyyy")}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="p-2 border-gray-300 border font-semibold"
                              style={{
                                background: "#f1f1f1",
                                color: "#7c7074",
                              }}
                            >
                              <div>Ocorrências</div>
                            </td>
                            <td
                              className="p-2 border-gray-300 border font-semibold"
                              style={{
                                background: "#f1f1f1",
                                color: "#7c7074",
                              }}
                            >
                              <div>Quantidade</div>
                            </td>
                            <td
                              className="p-2 border-gray-300 border font-semibold"
                              style={{
                                background: "#f1f1f1",
                                color: "#7c7074",
                              }}
                            >
                              <div>Valor</div>
                            </td>
                            <td
                              className="p-2 border-gray-300 border font-semibold"
                              style={{
                                background: "#f1f1f1",
                                color: "#7c7074",
                              }}
                            >
                              <div>Último registro</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              <div>Pendências Internas</div>
                            </td>
                            <td className="p-2 border-gray-300 border uppercase ">
                              <div>
                                <span style={{ color: "#738dc5" }}>
                                  Não constam ocorrências
                                </span>
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>-</div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>-</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              <div>Pendência Pefin</div>
                            </td>
                            <td className="p-2 border-gray-300 border uppercase ">
                              <div>
                                {data &&
                                data["Pendências REFIN/PEFIN"]?.length > 0 ? (
                                  data["Pendências REFIN/PEFIN"]?.length
                                ) : (
                                  <span style={{ color: "#738dc5" }}>
                                    Não constam ocorrências
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>
                                {data &&
                                data["Pendências REFIN/PEFIN"]?.length > 0
                                  ? formatToReal(
                                      sumTotal(
                                        data["Pendências REFIN/PEFIN"],
                                        "Vr Dívida"
                                      )
                                    )
                                  : "-"}
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>
                                {data &&
                                data["Pendências REFIN/PEFIN"]?.length > 0
                                  ? moment(
                                      data["Pendências REFIN/PEFIN"][0][
                                        "Dt Ocorr"
                                      ],
                                      "DD/MM/YYYY"
                                    ).format("DD/MM/yyyy")
                                  : "-"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              <div>Cheques Sem Fundo BACEN</div>
                            </td>
                            <td className="p-2 border-gray-300 border uppercase ">
                              <div>
                                <span style={{ color: "#738dc5" }}>
                                  Não constam ocorrências
                                </span>
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>-</div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>-</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="mt-5">
                    <div
                      className="font-semibold text-base md:text-2xl pb-1 border-b"
                      style={{ color: "#a7763c" }}
                    >
                      Detalhes do documento
                    </div>

                    <div className="flex items-center gap-2 mt-2">
                      <img src="/assets/danger.png" />
                      <div>
                        Situação do CPF/CNPJ em{" "}
                        {moment(data?.situacao?.rfb?.SIT_DATE).format(
                          "DD/MM/yyyy"
                        ) || "-"}
                      </div>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                              colSpan={4}
                            >
                              Participação Societária
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.socios?.length > 0 ? (
                            data?.socios?.map((socio, index) => (
                              <>
                                {" "}
                                <tr>
                                  <td
                                    className="p-2 border-gray-300 border font-semibold"
                                    style={{
                                      background: "#f1f1f1",
                                      color: "#7c7074",
                                    }}
                                  >
                                    <div>Empresa</div>
                                  </td>
                                  <td
                                    className="p-2 border-gray-300 border font-semibold"
                                    style={{
                                      background: "#f1f1f1",
                                      color: "#7c7074",
                                    }}
                                  >
                                    <div>CNPJ</div>
                                  </td>
                                  <td
                                    className="p-2 border-gray-300 border font-semibold"
                                    style={{
                                      background: "#f1f1f1",
                                      color: "#7c7074",
                                    }}
                                  >
                                    <div>Participação</div>
                                  </td>
                                  <td
                                    className="p-2 border-gray-300 border font-semibold"
                                    style={{
                                      background: "#f1f1f1",
                                      color: "#7c7074",
                                    }}
                                  >
                                    <div>UF</div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="p-2 border-gray-300 border">
                                    {socio.RAZAO_SOCIAL}
                                  </td>
                                  <td className="p-2 border-gray-300 border">
                                    {socio.CNPJ}
                                  </td>
                                  <td className="p-2 border-gray-300 border"></td>
                                  <td className="p-2 border-gray-300 border"></td>
                                </tr>
                                <tr>
                                  <td className="p-2 border-gray-300 border"></td>
                                  <td className="p-2 border-gray-300 border">
                                    <span className="font-semibold">
                                      Desde:{" "}
                                      {moment(socio.DATA_ENTRADA).format(
                                        "DD/MM/yyyy"
                                      )}
                                    </span>
                                  </td>
                                  <td
                                    className="p-2 border-gray-300 border"
                                    colSpan={2}
                                  >
                                    <span className="font-semibold">
                                      Última Atualização:
                                    </span>
                                  </td>
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={4}
                                className="p-2 border-gray-300 border"
                              >
                                ESTE {document?.length === 14 ? "CPF" : "CNPJ"}{" "}
                                NÃO POSSUI PARTICIPAÇÃO SOCIETÁRIA EM NENHUMA
                                EMPRESA
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Documentos Roubados, Furtados ou Extraviados
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              NAO CONSTAM OCORRENCIAS
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Pendências Internas
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              NAO CONSTAM OCORRENCIAS
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                              colSpan={8}
                            >
                              Pendências Financeiras Pefin
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                          data["Pendências REFIN/PEFIN"]?.length > 0 ? (
                            <>
                              {" "}
                              <tr>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Data</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Modalidade</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Aval</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Valor</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Contrato</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Origem</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>PÇA</div>
                                </td>
                              </tr>
                              {data &&
                                data["Pendências REFIN/PEFIN"]?.map(
                                  (serasa, index) => (
                                    <tr key={index}>
                                      <td className="p-2 border-gray-300 border">
                                        <div>{serasa["Dt Ocorr"]}</div>
                                      </td>
                                      <td className="p-2 border-gray-300 border">
                                        <div>{serasa["Tipo"]}</div>
                                      </td>
                                      <td className="p-2 border-gray-300 border">
                                        <div>{serasa["Tipo"]}</div>
                                      </td>
                                      <td className="p-2 border-gray-300 border">
                                        <div>{serasa["Vr Dívida"]}</div>
                                      </td>
                                      <td className="p-2 border-gray-300 border">
                                        <div>{serasa["Contrato"]}</div>
                                      </td>
                                      <td className="p-2 border-gray-300 border">
                                        <div>{serasa["Instituição"]}</div>
                                      </td>
                                      <td className="p-2 border-gray-300 border">
                                        <div></div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              <tr>
                                <td
                                  style={{ background: "#fffbed" }}
                                  className="p-2 border-gray-300 border"
                                  colSpan={8}
                                >
                                  Total de ocorrências :{" "}
                                  {data["Pendências REFIN/PEFIN"]?.length}{" "}
                                  Período de{" "}
                                  {moment(
                                    data["Pendências REFIN/PEFIN"][0][
                                      "Dt Ocorr"
                                    ],
                                    "DD/MM/YYYY"
                                  ).format("MM/yyyy")}{" "}
                                  a{" "}
                                  {moment(
                                    data["Pendências REFIN/PEFIN"][
                                      data["Pendências REFIN/PEFIN"]?.length - 1
                                    ]["Dt Ocorr"],
                                    "DD/MM/YYYY"
                                  ).format("MM/yyyy")}{" "}
                                  Valor total das ocorrências:{" "}
                                  {formatToReal(
                                    sumTotal(
                                      data["Pendências REFIN/PEFIN"],
                                      "Vr Dívida"
                                    )
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td
                                colSpan={8}
                                className="p-2 border-gray-300 border"
                              >
                                NAO CONSTAM OCORRENCIAS
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Cheques Sem Fundo BACEN
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              NAO CONSTAM OCORRENCIAS
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Consultas à Serasa realizadas ao mesmo documento
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              NAO CONSTAM OCORRENCIAS
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    className="border border-gray-200 p-2"
                    style={{ background: "#fffbed" }}
                  >
                    Simples consulta ao{" "}
                    {document?.length === 14 ? "CPF" : "CNPJ"} ({document}) no
                    cadastro da Serasa. Essa informação de consulta não
                    significa negócio realizado, nem se confunde com anotação
                    negativa no cadastro de inadimplentes.
                  </div>

                  <div className="border border-gray-200 p-2 flex items-center gap-1 font-semibold">
                    <span
                      style={{
                        color: "#43679c",
                      }}
                    >
                      PROTOCOLO DA CONSULTA:
                    </span>
                    <span style={{ color: "#b8243c" }}>
                      {" "}
                      {Math.floor(100000 + Math.random() * 900000)}
                    </span>
                  </div>

                  <div>
                    A decisão da aprovação ou não do crédito é de exclusiva
                    responsabilidade do concedente. As informações prestadas
                    pela Serasa Experian têm como objetivo subsidiar essas
                    decisões e, em hipótese alguma, devem ser utilizadas como
                    justificativa, pelo concedente do crédito, para a tomada da
                    referida decisão.
                  </div>
                </Card>
              </>
            ) : (
              <Card>
                <div role="status" className="w-full animate-pulse">
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                </div>
              </Card>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CrednetLightQuery;
