import moment from "moment";
import React, { useState } from "react";
import FilterToolbar from "../inputs/FilterToolbar";
import { Badge, Button, Pagination, Table } from "flowbite-react";
import { FaArrowsLeftRight } from "react-icons/fa6";
import axios from "../../config/axiosInstance";
import { useQuery, useQueryClient } from "react-query";
import { ADMIN, CATEGORIES } from "../../config/apiConfig";
import Drawer from "../Drawer";
import { formatToReal } from "../../utils/utils";
import { FaDollarSign, FaRegEdit } from "react-icons/fa";
import QueryEditForm from "../forms/QueryEditForm";
import { LuCalendarClock } from "react-icons/lu";
import { IoMdSearch } from "react-icons/io";
import TableLoading from "../TableLoading";

const AdminQueriesTable = () => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const onPageChange = (page) => setCurrentPage(page);
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [sortByPrice, setSortByPrice] = useState("");
  const [searchName, setSearchName] = useState("");
  const [nameParam, setNameParam] = useState("");
  const [isDrawerEditQuery, setIsDrawerEditQuery] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(null);

  const {
    isLoading: isLoadingListQueries,
    error: errorListQueries,
    data: listQueries,
  } = useQuery(
    ["listQueries", currentPage, nameParam, sortByPrice],
    async () => {
      try {
        const { data } = await axios.get(
          `${ADMIN.GET_QUERIES}?page=${currentPage}&searchName=${nameParam}&sortByPrice=${sortByPrice}`
        );
        setTotalPages(data.data.totalPages || 1);
        setTotalCount(data.data.totalCount || 0);
        return data.data.data;
      } catch (error) {
        console.error("Erro ao buscar dados:", error.message);
        throw new Error("Erro ao buscar dados.");
      }
    }
  );

  const handleSearchName = (e) => {
    e.preventDefault();
    setNameParam(searchName);
  };

  const handleEditQuery = (query) => {
    setSelectedQuery(query);
    setIsDrawerEditQuery((prevState) => !prevState);
  };

  return (
    <>
      <div className="flex justify-between mb-3 mt-2 items-center">
        <FilterToolbar
          isVisible={isVisibleFilter}
          setIsVisible={setIsVisibleFilter}
        >
          <div className="flex gap-2">
            <div className="flex gap-2 flex-col md:flex-row w-full">
              <div className="flex items-center gap-2 w-full bg-gray-100 rounded-md pl-2 pr-0 py-1">
                <FaDollarSign className="color-primary text-xl mt-1" />
                <select
                  id="underline_select"
                  className="mt-1 block py-1 px-0 w-full md:w-40 text-sm text-gray-600 bg-transparent border-0 border-gray-300 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  value={sortByPrice}
                  onChange={({ target }) => setSortByPrice(target.value)}
                >
                  <option selected value="">
                    Preço
                  </option>
                  <option value="DESC">Mais caros</option>
                  <option value="ASC">Mais baratos</option>
                </select>
              </div>
            </div>
          </div>
        </FilterToolbar>
      </div>
      <form
        className="flex gap-1 mt-5 w-full pl-3 pr-2 py-2 md:py-1 rounded-md shadow-sm bg-gray-300"
        onSubmit={handleSearchName}
      >
        <input
          type="text"
          placeholder="Busque por nome da consulta..."
          className="border-0 py-1 text-sm mt-1 focus:outline-none focus:ring-0 focus:border-gray-300 px-0 w-full bg-gray-300 placeholder:text-gray-700"
          onChange={(e) => setSearchName(e.target.value)}
          value={searchName}
        />
        <Button
          size="sm"
          className="flex items-center bg-secondary w-20"
          type="submit"
        >
          <IoMdSearch />
        </Button>
      </form>
      <small
        className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
        style={{ fontSize: 10 }}
      >
        <span>Deslize para o lado para visualizar todas as informações</span>{" "}
        <FaArrowsLeftRight />
      </small>
      <div className="overflow-x-auto shadow-md rounded-lg mt-0 md:mt-5">
        <Table striped>
          <Table.Head>
            <Table.HeadCell className="text-center bg-gray-300">
              ID
            </Table.HeadCell>
            <Table.HeadCell className="text-center bg-gray-300">
              Nome da consulta
            </Table.HeadCell>
            <Table.HeadCell className="text-center bg-gray-300">
              Provedor
            </Table.HeadCell>
            <Table.HeadCell className="text-center bg-gray-300">
              Categoria
            </Table.HeadCell>
            <Table.HeadCell className="text-center bg-gray-300">
              Preço Base
            </Table.HeadCell>
            <Table.HeadCell className="text-center bg-gray-300">
              Ações
            </Table.HeadCell>
          </Table.Head>

          <Table.Body className="divide-y">
            {!isLoadingListQueries ? (
              <>
                {listQueries?.length > 0 && (
                  <>
                    {listQueries.map((query, index) => (
                      <Table.Row
                        key={index}
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      >
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                          #{query.id}
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                          {query.name}
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                          {query?.Provider?.name}
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                          {query?.Category?.name}
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                          {formatToReal(query.price)}
                        </Table.Cell>
                        <Table.Cell className="text-center py-2">
                          <div className="flex gap-1 justify-center items-center flex-wrap">
                            <Button
                              className="p-0"
                              onClick={() => handleEditQuery(query)}
                            >
                              <FaRegEdit fontSize="16" />
                            </Button>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </>
                )}
              </>
            ) : (
              <TableLoading cols={6} />
            )}
          </Table.Body>
        </Table>
      </div>
      {totalCount > 0 && (
        <div className="mt-3 text-center text-sm">
          Foram encontrados {totalCount} registros no total
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}
      <Drawer
        title={`Editar consulta`}
        onClose={setIsDrawerEditQuery}
        isOpen={isDrawerEditQuery}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <QueryEditForm
          query={selectedQuery}
          fetchQueries={() => {
            queryClient.invalidateQueries(["listQueries", currentPage]);
          }}
          onClose={setIsDrawerEditQuery}
        />
      </Drawer>
    </>
  );
};

export default AdminQueriesTable;
