import { Card } from "flowbite-react";
import { FaRegCheckCircle } from "react-icons/fa";

const CardGreen = ({ title, message }) => {
  return (
    <Card className="bg-green-500 mb-3 flex-1">
      <div className="flex gap-2 items-center text-white font-semibold flex flex-col text-center">
        <div>
          <FaRegCheckCircle fontSize={20} />
        </div>
        <div>
          {title} <br />
          {message}
        </div>
      </div>
    </Card>
  );
};

export default CardGreen;
