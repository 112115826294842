import { Table } from "flowbite-react";
import React from "react";

const TableLoading = ({ cols = 4 }) => {
  return (
    <>
      {Array.from({ length: 12 }).map((_, index) => (
        <Table.Row
          key={index}
          className="bg-white dark:border-gray-700 dark:bg-gray-800"
        >
          {Array.from({ length: cols }).map((_, indexCol) => (
            <Table.Cell
              className="font-medium text-gray-900 dark:text-white text-center py-2"
              key={indexCol}
            >
              <div role="status" className="max-w-sm animate-pulse inline">
                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
              </div>
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </>
  );
};

export default TableLoading;
