import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Table,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { formatToReal, isValidCNPJ, isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import usePDF from "../../hooks/usePDF";
import CardQueryDescription from "../common/CardQueryDescription";
import moment from "moment";
import QueryInput from "../inputs/QueryInput";

const SerasaBasicoQuery4 = ({ queryId, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const [score, setScore] = useState(0);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    queryId,
    data
  );

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_SERASA_BASICO_4}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setDocument(info);
    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    }
    // else {
    //   if (!isValidCNPJ(unmaskedValue)) {
    //     setError("CNPJ inválido!");
    //     isValid = false;
    //   }
    // }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const sumTotal = (arr, key) => {
    return arr?.reduce(
      (acc, curr) =>
        acc +
        parseFloat(
          curr[key]
            .replace("R$", "")
            .replace(/\./g, "")
            .replace(",", ".")
            .trim()
        ),
      0
    );
  };

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    if (data) {
      setScore(
        data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.QUANTIDADE_OCORRENCIA !== "0"
          ? getRandomNumber(200, 400)
          : getRandomNumber(600, 800)
      );
    }
  }, data);

  const getImageScoreSale = () => {
    let scoreLetter;

    if (score <= 200) {
      scoreLetter = "1";
    } else if (score <= 400) {
      scoreLetter = "2";
    } else if (score <= 600) {
      scoreLetter = "3";
    } else if (score <= 800) {
      scoreLetter = "4";
    } else {
      scoreLetter = "5";
    }

    return `/assets/score/score_${scoreLetter}.png`;
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Serasa Relatório Básico
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Serasa",
          "Dados Cadastrais",
          "Participações societárias",
          "Pend. Internas",
          "Protesto Nacional",
          "Cheques sem fundo",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-2 items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF"
            />
            {!errorQuery && data && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
        </form>
      </CardQueryDescription>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`${data || isLoading ? "block" : "hidden"}`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="mt-2 p-5">
                  <div className="flex items-center justify-between mb-5">
                    <img className="w-40" src="/assets/logo_serasa.png" />
                  </div>

                  <div>
                    <div
                      className="font-semibold text-base md:text-2xl pb-1 border-b"
                      style={{ color: "#a7763c" }}
                    >
                      Serasa Relatório Básico
                    </div>

                    <div className="mt-5 flex justify-end">
                      {moment().format("DD MMMM yyyy HH:mm:ss")}
                    </div>

                    <div className="overflow-scroll-x mt-1">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              CPF/CNPJ
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Status
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Banco
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Agência
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Conta
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Cheque
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              <div>{document}</div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div>
                                {" "}
                                {
                                  data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                    ?.QUANTIDADE_OCORRENCIA
                                }{" "}
                                anotações
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div></div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div></div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div></div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div></div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="mt-5">
                    <div
                      className="font-semibold text-base md:text-2xl pb-1 border-b"
                      style={{ color: "#a7763c" }}
                    >
                      Resumo da consulta
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              {document?.length === 14 ? "CPF" : "CNPJ"}
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              {document?.length === 14
                                ? "Nome"
                                : "Razão Social"}
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              {document?.length === 14
                                ? "Nome da mãe"
                                : "Natureza"}
                            </td>
                            <td
                              className="p-1 font-semibold text-white uppercase text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              {document?.length === 14
                                ? "Data de Nascimento"
                                : "Data de Fundação"}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              <div>{document}</div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div>
                                {document?.length === 14
                                  ? data?.cadastral?.NOME
                                  : data?.cadastral?.RAZAO_SOCIAL}
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div>
                                {document?.length === 14
                                  ? data?.cadastral?.NOME_MAE
                                  : data?.cadastral?.NATUREZA_DESCRICAO}
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border">
                              <div>
                                {document?.length === 14
                                  ? moment(
                                      data?.cadastral?.DATA_NASCIMENTO
                                    ).format("DD/MM/yyyy")
                                  : moment(
                                      data?.cadastral?.DATA_ABERTURA
                                    ).format("DD/MM/yyyy")}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="p-2 border-gray-300 border font-semibold"
                              style={{
                                background: "#f1f1f1",
                                color: "#7c7074",
                              }}
                            >
                              <div>Ocorrências</div>
                            </td>
                            <td
                              className="p-2 border-gray-300 border font-semibold"
                              style={{
                                background: "#f1f1f1",
                                color: "#7c7074",
                              }}
                            >
                              <div>Quantidade</div>
                            </td>
                            <td
                              className="p-2 border-gray-300 border font-semibold"
                              style={{
                                background: "#f1f1f1",
                                color: "#7c7074",
                              }}
                            >
                              <div>Valor</div>
                            </td>
                            <td
                              className="p-2 border-gray-300 border font-semibold"
                              style={{
                                background: "#f1f1f1",
                                color: "#7c7074",
                              }}
                            >
                              <div>Último registro</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              <div>Pendências Internas</div>
                            </td>
                            <td className="p-2 border-gray-300 border uppercase ">
                              <div>
                                {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                  ?.QUANTIDADE_OCORRENCIA !== "0" ? (
                                  data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                    ?.QUANTIDADE_OCORRENCIA
                                ) : (
                                  <span style={{ color: "#738dc5" }}>
                                    Não constam ocorrências
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>
                                {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                  ?.QUANTIDADE_OCORRENCIA !== "0"
                                  ? formatToReal(
                                      data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.VALOR_TOTAL?.replace(
                                        ",",
                                        "."
                                      )
                                    )
                                  : "-"}
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>
                                {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                  ?.QUANTIDADE_OCORRENCIA !== "0"
                                  ? data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                      ?.ULTIMO_VENCIMENTO
                                  : "-"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              <div>Protesto Nacional</div>
                            </td>
                            <td className="p-2 border-gray-300 border uppercase ">
                              <div>
                                <span style={{ color: "#738dc5" }}>
                                  Não constam ocorrências
                                </span>
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>-</div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>-</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              <div>Cheques Sem Fundo BACEN</div>
                            </td>
                            <td className="p-2 border-gray-300 border uppercase ">
                              <div>
                                <span style={{ color: "#738dc5" }}>
                                  Não constam ocorrências
                                </span>
                              </div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>-</div>
                            </td>
                            <td className="p-2 border-gray-300 border text-right">
                              <div>-</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="mt-5">
                    <div
                      className="font-semibold text-base md:text-2xl pb-1 border-b"
                      style={{ color: "#a7763c" }}
                    >
                      Detalhes do documento
                    </div>

                    <div className="flex items-center gap-2 mt-2 mb-2">
                      <img src="/assets/danger.png" />
                      <div>
                        Situação do CPF/CNPJ em{" "}
                        {moment(data?.situacao?.rfb?.SIT_DATE).format(
                          "DD/MM/yyyy"
                        ) || "-"}
                      </div>
                    </div>

                    <div className="border-gray-300 border">
                      <div
                        className="p-1 font-semibold text-white text-sm border-gray-300 border-b"
                        style={{
                          background: "#ecf1f7",
                          color: "#43679c",
                        }}
                      >
                        Serasa Score com Positivo
                      </div>

                      <div className="flex flex-col md:flex-row items-center gap-4 p-4">
                        <div className="flex-1 mt-2">
                          <img
                            className="px-6"
                            src={`${getImageScoreSale()}`}
                          />
                          <Badge className="text-center font-semibold text-lg w-fit mx-auto bg-blue-100 mt-1">
                            {score}
                          </Badge>
                        </div>
                        <div
                          className="flex flex-col gap-2 items-center text-white flex-initial p-4"
                          style={{ background: "#5d95cb" }}
                        >
                          <div className="font-semibold text-2xl">
                            {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                              ?.QUANTIDADE_OCORRENCIA !== "0"
                              ? getRandomNumber(1, 40)
                              : getRandomNumber(40, 90)}
                            %
                          </div>
                          <div>chance de pagamento</div>
                        </div>
                        <div
                          className="p-2 flex-1"
                          style={{
                            border: "solid 1px #5d95cb",
                            color: "#5d95cb",
                          }}
                        >
                          A decisão da aprovação ou não do crédito é de
                          exclusiva responsabilidade do concedente. As
                          informações prestadas pela Serasa Experian têm como
                          objetivo subsidiar essas decisões e, em hipótese
                          alguma, devem ser utilizadas como justificativa, pelo
                          concedente do crédito, para a tomada da referida
                          decisão
                        </div>
                      </div>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                              colSpan={4}
                            >
                              Participação Societária
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.socios?.length > 0 ? (
                            data?.socios?.map((socio, index) => (
                              <>
                                {" "}
                                <tr>
                                  <td
                                    className="p-2 border-gray-300 border font-semibold"
                                    style={{
                                      background: "#f1f1f1",
                                      color: "#7c7074",
                                    }}
                                  >
                                    <div>Empresa</div>
                                  </td>
                                  <td
                                    className="p-2 border-gray-300 border font-semibold"
                                    style={{
                                      background: "#f1f1f1",
                                      color: "#7c7074",
                                    }}
                                  >
                                    <div>CNPJ</div>
                                  </td>
                                  <td
                                    className="p-2 border-gray-300 border font-semibold"
                                    style={{
                                      background: "#f1f1f1",
                                      color: "#7c7074",
                                    }}
                                  >
                                    <div>Participação</div>
                                  </td>
                                  <td
                                    className="p-2 border-gray-300 border font-semibold"
                                    style={{
                                      background: "#f1f1f1",
                                      color: "#7c7074",
                                    }}
                                  >
                                    <div>UF</div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="p-2 border-gray-300 border">
                                    {socio.RAZAO_SOCIAL}
                                  </td>
                                  <td className="p-2 border-gray-300 border">
                                    {socio.CNPJ}
                                  </td>
                                  <td className="p-2 border-gray-300 border"></td>
                                  <td className="p-2 border-gray-300 border"></td>
                                </tr>
                                <tr>
                                  <td className="p-2 border-gray-300 border"></td>
                                  <td className="p-2 border-gray-300 border">
                                    <span className="font-semibold">
                                      Desde:{" "}
                                      {moment(socio.DATA_ENTRADA).format(
                                        "DD/MM/yyyy"
                                      )}
                                    </span>
                                  </td>
                                  <td
                                    className="p-2 border-gray-300 border"
                                    colSpan={2}
                                  >
                                    <span className="font-semibold">
                                      Última Atualização:
                                    </span>
                                  </td>
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={4}
                                className="p-2 border-gray-300 border"
                              >
                                ESTE {document?.length === 14 ? "CPF" : "CNPJ"}{" "}
                                NÃO POSSUI PARTICIPAÇÃO SOCIETÁRIA EM NENHUMA
                                EMPRESA
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Documentos Roubados, Furtados ou Extraviados
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              NAO CONSTAM OCORRENCIAS
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                              colSpan={8}
                            >
                              Pendências Internas
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                            ?.QUANTIDADE_OCORRENCIA !== "0" ? (
                            <>
                              {" "}
                              <tr>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Data</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Modalidade</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Aval</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Valor</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Contrato</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>Origem</div>
                                </td>
                                <td
                                  className="p-2 border-gray-300 border font-semibold"
                                  style={{
                                    background: "#f1f1f1",
                                    color: "#7c7074",
                                  }}
                                >
                                  <div>PÇA</div>
                                </td>
                              </tr>
                              {data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.OCORRENCIAS?.map(
                                (serasa, index) => (
                                  <tr key={index}>
                                    <td className="p-2 border-gray-300 border">
                                      <div>{serasa.DATA_INCLUSAO}</div>
                                    </td>
                                    <td className="p-2 border-gray-300 border">
                                      <div>{serasa.MODALIDADE}</div>
                                    </td>
                                    <td className="p-2 border-gray-300 border">
                                      <div>{serasa.TIPO_DEVEDOR}</div>
                                    </td>
                                    <td className="p-2 border-gray-300 border">
                                      <div>
                                        {formatToReal(
                                          serasa.VALOR?.replace(",", ".")
                                        )}
                                      </div>
                                    </td>
                                    <td className="p-2 border-gray-300 border">
                                      <div>{serasa.CONTRATO}</div>
                                    </td>
                                    <td className="p-2 border-gray-300 border">
                                      <div>{serasa.CREDOR}</div>
                                    </td>
                                    <td className="p-2 border-gray-300 border">
                                      <div></div>
                                    </td>
                                  </tr>
                                )
                              )}
                              <tr>
                                <td
                                  style={{ background: "#fffbed" }}
                                  className="p-2 border-gray-300 border"
                                  colSpan={8}
                                >
                                  Total de ocorrências :{" "}
                                  {
                                    data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                      ?.QUANTIDADE_OCORRENCIA
                                  }{" "}
                                  Período de{" "}
                                  {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                    ?.PERIODO_INICIAL || "-"}
                                  a{" "}
                                  {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                    ?.PERIODO_FINAL || "-"}
                                  Valor total das ocorrências:{" "}
                                  {formatToReal(
                                    data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.VALOR_TOTAL?.replace(
                                      ",",
                                      "."
                                    )
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td
                                colSpan={8}
                                className="p-2 border-gray-300 border"
                              >
                                NAO CONSTAM OCORRENCIAS
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                              colSpan={8}
                            >
                              Protesto Nacional
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              colSpan={8}
                              className="p-2 border-gray-300 border"
                            >
                              NAO CONSTAM OCORRENCIAS
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Cheques Sem Fundo BACEN
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              NAO CONSTAM OCORRENCIAS
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="overflow-scroll-x mt-4">
                      <table className="rounded w-full border-collapse">
                        <thead>
                          <tr>
                            <td
                              className="p-1 font-semibold text-white text-sm border-gray-300 border"
                              style={{
                                background: "#ecf1f7",
                                color: "#43679c",
                              }}
                            >
                              Consultas à Serasa realizadas ao mesmo documento
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-gray-300 border">
                              NAO CONSTAM OCORRENCIAS
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    className="border border-gray-200 p-2"
                    style={{ background: "#fffbed" }}
                  >
                    Simples consulta ao{" "}
                    {document?.length === 14 ? "CPF" : "CNPJ"} ({document}) no
                    cadastro da Serasa. Essa informação de consulta não
                    significa negócio realizado, nem se confunde com anotação
                    negativa no cadastro de inadimplentes.
                  </div>

                  <div className="border border-gray-200 p-2 flex items-center gap-1 font-semibold">
                    <span
                      style={{
                        color: "#43679c",
                      }}
                    >
                      PROTOCOLO DA CONSULTA:
                    </span>
                    <span style={{ color: "#b8243c" }}>
                      {" "}
                      {Math.floor(100000 + Math.random() * 900000)}
                    </span>
                  </div>

                  <div>
                    A decisão da aprovação ou não do crédito é de exclusiva
                    responsabilidade do concedente. As informações prestadas
                    pela Serasa Experian têm como objetivo subsidiar essas
                    decisões e, em hipótese alguma, devem ser utilizadas como
                    justificativa, pelo concedente do crédito, para a tomada da
                    referida decisão.
                  </div>

                  <div className="mt-10">
                    <div className="font-semibold mb-2">
                      INFORMAÇÕES IMPORTANTES
                    </div>
                    <div>
                      ESTAS INFORMAÇÕES SÃO CONFIDENCIAIS E DEVERÃO SER
                      UTILIZADAS, ÚNICA E EXCLUSIVAMENTE, PARA ORIENTAÇÃO DAS
                      TRANSAÇÕES COMERCIAIS DO USUÁRIO , RESPONSABILIZANDO-SE
                      CIVIL E CRIMINALMENTE POR DANOS QUE OCASIONAR A TERCEIROS,
                      QUANDO UTILIZADAS EM DESACORDO COM A LEGISLAÇÃO EM VIGOR.
                      EM CASO DE DÚVIDAS ENTRE EM CONTATO PELO FALE CONOSCO.
                    </div>
                    <div className="font-semibold mb-2 mt-4">AVISO</div>
                    <div>
                      AINDA QUE EMPREGANDO OS MELHORES ESFORCOS, NOSSA EMPRESA
                      SE EXIME DE QUALQUER RESPONSABILIDADE PELA EVENTUAL NAO
                      INCLUSAO DE ALGUM REGISTRO EM RAZAO DE ATRASO OU FALTA DO
                      ENCAMINHAMENTO DOS DADOS PELOS DIVERSOS PROVEDORES DE
                      INFORMACOES CONVENIADOS. EM CASO DE DUVIDAS ENTRE EM
                      CONTATO COM NOSSO SUPORTE.
                    </div>
                    <div className="font-semibold mb-2 mt-4">
                      MINUTA DE DECLARAÇÃO LGPD
                    </div>
                    <div>
                      DECLARO QUE A UTILIZAÇÃO DOS DADOS RESPEITARÁ AS
                      FINALIDADES E PROCEDIMENTOS LEGALMENTE ADMITIDOS PELA LEI
                      GERAL DE PROTEÇÃO DE DADOS DIVULGADOS EM MINHA PÁGINA
                      INICIAL DE ACESSO.
                    </div>
                  </div>
                </Card>
              </>
            ) : (
              <Card>
                <div role="status" className="w-full animate-pulse">
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                </div>
              </Card>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SerasaBasicoQuery4;
