import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Table,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaSearch } from "react-icons/fa";
import { formatToReal, isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCPF } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import { BOA_VISTA_CONTANTS } from "../../utils/boa-vista-variables";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import CardQueryDescription from "../common/CardQueryDescription";
import ReactApexChart from "react-apexcharts";
import TitleStripe from "../common/TitleStripe";

const AcertaBVCPFSemProtestoQuery = ({ queryId, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const [score, setScore] = useState(0);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    queryId,
    data
  );

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    valor = maskCPF(valor);

    setInfo(valor);
  };

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_ACERTA_BV_CPF_SEM_PROTESTO}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CPF inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    if (data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.OCORRENCIAS) {
      setScore(
        data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.OCORRENCIAS?.length > 0
          ? getRandomNumber(200, 400)
          : getRandomNumber(600, 800)
      );
    }
  }, data);

  const checkColorScore = () => {
    if (score >= 800) {
      return "#01B000";
    } else if (score >= 600) {
      return "#8DED01";
    } else if (score >= 400) {
      return "#e5e000";
    } else if (score >= 200) {
      return "#FFBC00";
    } else {
      return "#db2e45";
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Acerta BV CPF Sem Protesto
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={["Dados Cadastrais", "Pendências financeiras"]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-2 items-end">
            <div className="flex items-center gap-2">
              <div className="block">
                <Label htmlFor="info" value="CPF:" />
              </div>
              <TextInput
                id="info"
                type="text"
                required
                onChange={handleChange}
                maxLength="14"
                value={info}
              />
            </div>
            <Button
              className=" shadow-lg primary flex items-center justify-center"
              type="submit"
              isProcessing={isLoading}
              processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
              style={{ height: "43px" }}
            >
              <FaSearch />
            </Button>
            {!errorQuery && data && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </CardQueryDescription>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`${data || isLoading ? "block" : "hidden"}`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="mt-2">
                  <HeaderQuery
                    imgHeader="/assets/logo_boa_vista.png"
                    size={"small"}
                  />

                  <table className="border boder-gray-200 rounded">
                    <thead>
                      <tr>
                        <td className="p-2 font-semibold text-primary">
                          Identificação
                        </td>
                      </tr>
                    </thead>
                    <tbody style={{ background: "#E9EDEE" }}>
                      <tr className="border-b border-white">
                        <td className="p-2">
                          <div className="font-semibold text-primary">Nome</div>
                          <div>
                            {data?.CREDCADASTRAL?.DADOS_RECEITA_FEDERAL?.NOME}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">CPF</div>
                          <div>{info}</div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Nome da mãe
                          </div>
                          <div>
                            {data?.CREDCADASTRAL?.DADOS_RECEITA_FEDERAL
                              ?.NOME_MAE || "Não informado"}
                          </div>
                        </td>
                      </tr>
                      <tr className="border-b border-white">
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Situação do CPF
                          </div>
                          <div>
                            {BOA_VISTA_CONTANTS.SITUATION[
                              data?.CREDCADASTRAL?.DADOS_RECEITA_FEDERAL
                                ?.SITUACAO_RECEITA
                            ] || "Não informado"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            Data de nascimento
                          </div>
                          <div>
                            {data?.CREDCADASTRAL?.DADOS_RECEITA_FEDERAL
                              ?.DATA_NASCIMENTO_FUNDACAO || "Não informado"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="font-semibold text-primary">
                            E-mail
                          </div>
                          <div>
                            {data?.CREDCADASTRAL?.DADOS_RECEITA_FEDERAL
                              ?.EMAIL || "Não informado"}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="border boder-gray-200 p-2">
                    <div className="font-semibold text-primary mb-3">
                      Novo risco 6 meses
                    </div>
                    <div className="flex gap-2">
                      <div
                        className="rounded h-40 flex justify-center items-center text-white text-center px-6 text-3xl font-semibold w-2/5"
                        style={{
                          background: `${checkColorScore(score)}`,
                        }}
                      >
                        Score <br /> {+score}
                      </div>
                      <div className="rounded h-40 flex justify-center items-center border border-gray-200 px-6 font-semibold">
                        De cada 100 pessoas classificadas nesta classe de score,
                        é provável que 14 apresentem débitos no mercado nos
                        próximos 6 meses.
                      </div>
                      <div className="w-4/5 min-w-4/5 pt-3">
                        <ReactApexChart
                          options={{
                            chart: {
                              type: "radialBar",
                              offsetY: -20,
                              sparkline: {
                                enabled: true,
                              },
                            },
                            plotOptions: {
                              radialBar: {
                                startAngle: -90,
                                endAngle: 90,
                                track: {
                                  background: "#e7e7e7",
                                  strokeWidth: "97%",
                                  margin: 5, // margin is in pixels
                                  dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: "#999",
                                    opacity: 1,
                                    blur: 2,
                                  },
                                },
                                dataLabels: {
                                  name: {
                                    show: false,
                                  },
                                  value: {
                                    offsetY: -2,
                                    fontSize: "22px",
                                    formatter: function (val) {
                                      return val * 10;
                                    },
                                  },
                                },
                              },
                            },
                            grid: {
                              padding: {
                                top: -10,
                              },
                            },
                            fill: {
                              type: "solid",
                              gradient: {
                                shade: "light",
                                shadeIntensity: 0.4,
                                inverseColors: false,
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 50, 53, 91],
                              },
                              colors: (() => {
                                return checkColorScore();
                              })(),
                            },
                          }}
                          series={[score / 10]}
                          type="radialBar"
                        />
                      </div>
                    </div>
                  </div>

                  <table id="debitos" className="border boder-gray-200 rounded">
                    <thead>
                      <tr>
                        <td
                          colSpan={9}
                          className="p-2 font-semibold text-primary"
                        >
                          Registros de débito
                        </td>
                      </tr>
                    </thead>
                    {data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.OCORRENCIAS
                      ?.length > 0 ? (
                      <tbody
                        style={{ background: "#E9EDEE" }}
                        className="text-sm"
                      >
                        <tr className="border-b border-white">
                          <td className="p-2 text-center" colSpan={9}>
                            <span className="font-semibold text-primary">
                              Valor total:{" "}
                            </span>
                            <span>
                              R${" "}
                              {
                                data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                  ?.VALOR_TOTAL
                              }
                            </span>
                          </td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="p-2" colSpan={4}>
                            <span className="font-semibold text-primary">
                              Data do primeiro registro:{" "}
                            </span>
                            <span>
                              {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                ?.DATA_PRIMEIRO || "Não informado"}
                            </span>
                          </td>
                          <td className="p-2" colSpan={5}>
                            <span className="font-semibold text-primary">
                              Valor do primeiro (R$):{" "}
                            </span>
                            <span>
                              {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                ?.VALOR_PRIMEIRO || "Não informado"}
                            </span>
                          </td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="p-2" colSpan={4}>
                            <span className="font-semibold text-primary">
                              Data do maior registro:{" "}
                            </span>
                            <span>
                              {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                ?.DATA_MAIOR || "Não informado"}
                            </span>
                          </td>
                          <td className="p-2" colSpan={5}>
                            <span className="font-semibold text-primary">
                              Valor do maior (R$):{" "}
                            </span>
                            <span>
                              {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                ?.VALOR_MAIOR || "Não informado"}
                            </span>
                          </td>
                        </tr>
                        <tr className="border-b border-white">
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Dt. Inclusão
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Dt. Vencimento
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Credor
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Origem
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Valor (R$)
                            </div>
                          </td>
                        </tr>

                        <>
                          {data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.OCORRENCIAS?.map(
                            (debito, index) => (
                              <tr
                                key={index}
                                className={`${index % 2 === 0 && "bg-white"}`}
                              >
                                <td className="text-center py-2">
                                  {debito.DATA_INCLUSAO}
                                </td>
                                <td className="text-center py-2">
                                  {debito.DATA_VENCIMENTO}
                                </td>
                                <td className="text-center py-2">
                                  {debito.CREDOR}
                                </td>
                                <td className="text-center py-2">
                                  {debito.ORIGEM}
                                </td>
                                <td className="text-center py-2">
                                  {debito.VALOR}
                                </td>
                              </tr>
                            )
                          )}
                        </>
                      </tbody>
                    ) : (
                      <tbody style={{ background: "#E9EDEE" }}>
                        <tr>
                          <td className="text-center p-2" colSpan={5}>
                            Nada consta
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                  <TitleStripe>Alertas/Informações fixas</TitleStripe>
                  <div>
                    <div className="font-semibold">Informações importantes</div>
                    Estas informações são confidenciais e deverão ser
                    utilizadas, única e exclusivamente, para orientação das
                    transações comerciais do usuário , responsabilizando-se
                    civil e criminalmente por danos que ocasionar a terceiros,
                    quando utilizadas em desacordo com a legislação em vigor. Em
                    caso de dúvidas entre em contato pelo fale conosco.
                  </div>
                  <div>
                    <div className="font-semibold">Aviso</div>
                    Ainda que empregando os melhores esforcos, nossa empresa se
                    exime de qualquer responsabilidade pela eventual não
                    inclusao de algum registro em razão de atraso ou falta do
                    encaminhamento dos dados pelos diversos provedores de
                    informações conveniados. Em caso de duvidas entre em contato
                    com nosso suporte.
                  </div>
                  <div>
                    <div className="font-semibold">
                      Minuta de declaração LGPD
                    </div>
                    Declaro que a utilização dos dados respeitará as finalidades
                    e procedimentos legalmente admitidos pela lei geral de
                    proteção de dados divulgados em minha página inicial de
                    acesso.
                  </div>
                </Card>
              </>
            ) : (
              <Card>
                <div role="status" className="w-full animate-pulse">
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                </div>
              </Card>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AcertaBVCPFSemProtestoQuery;
