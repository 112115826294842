const TitleStripe = ({ children, className }) => {
  return (
    <div
      className={`px-2 py-2 bg-primary text-white font-semibold mt-5 ${className}`}
    >
      {children}
    </div>
  );
};

export default TitleStripe;
