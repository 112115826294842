export const maskCPF = (cpf) => {
  if (cpf.length <= 11) {
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  }
  return cpf;
};

export const maskCNPJ = (cnpj) => {
  if (cnpj.length <= 14) {
    cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
  }
  return cnpj;
};

export const maskPhone = (phone) => {
  phone = phone.replace(/\D/g, "");
  if (phone.length <= 11) {
    phone = phone.replace(/(\d{2})(\d)/, "($1) $2");
    phone = phone.replace(/(\d{5})(\d)/, "$1-$2");
  }
  return phone;
};
