import React, { useEffect, useState } from "react";
import { QUERIES } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { uploadPDF } from "../utils/utils";

const usePDF = (componentRef, info, queryId, data) => {
  const [pdfURL, setPdfURL] = useState("");

  const handleClickDownloadPDF = async () => {
    const link = document.createElement("a");
    link.href = pdfURL;
    link.download = info + ".pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const upload = async () => {
      const response = await uploadPDF(componentRef);
      setPdfURL(response || "");
    };

    if (data) {
      setTimeout(() => {
        upload();
      }, 1000);
    }
  }, [data]);

  return { pdfURL, setPdfURL, handleClickDownloadPDF };
};

export default usePDF;
