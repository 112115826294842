import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Table,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaSearch } from "react-icons/fa";
import { formatToReal, isValidCPF } from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCPF } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import moment from "moment";
import QueryInput from "../inputs/QueryInput";

const SpcCPFQuery4 = ({ queryId, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const [score, setScore] = useState(0);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    queryId,
    data
  );

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES.GET_SPC_CPF_4}/${info.replace(/\D/g, "")}`);
  };

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const checkColorScore = () => {
    if (score >= 800) {
      return "#01B000";
    } else if (score >= 600) {
      return "#8DED01";
    } else if (score >= 400) {
      return "#e5e000";
    } else if (score >= 200) {
      return "#FFBC00";
    } else {
      return "#db2e45";
    }
  };

  useEffect(() => {
    let score = 0;

    if (data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.QUANTIDADE_OCORRENCIA !== "0") {
      score = getRandomNumber(200, 400);
    } else {
      score = getRandomNumber(600, 800);
    }

    setScore(score);
  }, [data]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CPF inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta. Possíveis causas: O CPF pode ter algum um bloqueio geral no SPC para consultas de empresas ou pode ser PEP"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">SPC CPF</div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score",
          "Pendências financeiras",
          "Protestos",
          "Cheques sem fundos",
          "Cheques sustados",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-2 items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF"
            />
            {!errorQuery && data && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
        </form>
      </CardQueryDescription>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`${data || isLoading ? "block" : "hidden"}`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="mt-2 p-5">
                  <HeaderQuery imgHeader="/assets/logo_spc.png" size="small" />

                  <div>
                    <TitleStripe className="mb-4">
                      Informações Gerais
                    </TitleStripe>
                    <div className="flex justify-between">
                      <div className="flex-1 flex flex-col gap-2">
                        <div className="flex gap-2 items-center">
                          <div className="font-semibold">Nome do cliente:</div>
                          <div>{data?.cadastral?.NOME}</div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <div className="font-semibold">CPF/CNPJ:</div>
                          <div>{document}</div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <div className="font-semibold">Data:</div>
                          <div>{moment().format("DD/MM/YYYY")}</div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <div className="font-semibold">Escolaridade:</div>
                          <div>{data?.Escolaridade || "Não informado"}</div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <div className="font-semibold">Nome da mãe:</div>
                          <div>
                            {data?.cadastral?.NOME_MAE || "Não informado"}
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col gap-2">
                        <div className="flex gap-2 items-center">
                          <div className="font-semibold">Estado civil:</div>
                          <div>{data?.EstadoCivil || "Não informado"}</div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <div className="font-semibold">Dependentes:</div>
                          <div>{data?.Dependentes || "Não informado"}</div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <div className="font-semibold">Sexo:</div>
                          <div>{data?.cadastral?.SEXO || "Não informado"}</div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <div className="font-semibold">Signo:</div>
                          <div>{data?.Signo || "Não informado"}</div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <div className="font-semibold">Nacionalidade:</div>
                          <div>{data?.Nacionalidade || "Não informado"}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <TitleStripe className="mb-4">SPC Brasil</TitleStripe>

                  <div>
                    <div className="w-1/2 mx-auto">
                      <ReactApexChart
                        options={{
                          chart: {
                            type: "radialBar",
                            offsetY: -20,
                            sparkline: {
                              enabled: true,
                            },
                          },
                          plotOptions: {
                            radialBar: {
                              startAngle: -90,
                              endAngle: 90,
                              track: {
                                background: "#e7e7e7",
                                strokeWidth: "97%",
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                  enabled: true,
                                  top: 2,
                                  left: 0,
                                  color: "#999",
                                  opacity: 1,
                                  blur: 2,
                                },
                              },
                              dataLabels: {
                                name: {
                                  show: false,
                                },
                                value: {
                                  offsetY: -2,
                                  fontSize: "22px",
                                  formatter: function (val) {
                                    return val * 10;
                                  },
                                },
                              },
                            },
                          },
                          grid: {
                            padding: {
                              top: -10,
                            },
                          },
                          fill: {
                            type: "solid",
                            gradient: {
                              shade: "light",
                              shadeIntensity: 0.4,
                              inverseColors: false,
                              opacityFrom: 1,
                              opacityTo: 1,
                              stops: [0, 50, 53, 91],
                            },
                            colors: (() => {
                              return checkColorScore();
                            })(),
                          },
                        }}
                        series={[score / 10]}
                        type="radialBar"
                      />
                    </div>
                  </div>

                  <div className="title-syncx">Débitos SPC</div>

                  {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                    ?.QUANTIDADE_OCORRENCIA !== "0" ? (
                    <table className="border boder-gray-200 rounded">
                      <thead style={{ background: "#E9EDEE" }}>
                        <tr className="border-b border-white">
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Empresa
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Nº contrato
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Valor inicial
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <div className="font-semibold text-primary">
                              Valor Atual
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <tbody style={{ background: "#E9EDEE" }}>
                        {data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.OCORRENCIAS?.map(
                          (debito, index) => (
                            <tr
                              key={index}
                              className={`${index % 2 === 0 && "bg-white"}`}
                            >
                              <td className="text-center py-2">
                                {debito.CREDOR || "-"}
                              </td>
                              <td className="text-center py-2">
                                {debito.CONTRATO || "-"}
                              </td>
                              <td className="text-center py-2">
                                {formatToReal(
                                  debito.VALOR?.replace(",", ".")
                                ) || "-"}
                              </td>
                              <td className="text-center py-2">
                                {formatToReal(
                                  debito.VALOR?.replace(",", ".")
                                ) || "-"}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <Alert color="gray" className="font-semibold">
                      Nada consta
                    </Alert>
                  )}

                  <TitleStripe>Alertas/Informações fixas</TitleStripe>
                  <div>
                    <div className="font-semibold">Informações importantes</div>
                    Estas informações são confidenciais e deverão ser
                    utilizadas, única e exclusivamente, para orientação das
                    transações comerciais do usuário , responsabilizando-se
                    civil e criminalmente por danos que ocasionar a terceiros,
                    quando utilizadas em desacordo com a legislação em vigor. Em
                    caso de dúvidas entre em contato pelo fale conosco.
                  </div>
                  <div>
                    <div className="font-semibold">Aviso</div>
                    Ainda que empregando os melhores esforcos, nossa empresa se
                    exime de qualquer responsabilidade pela eventual não
                    inclusao de algum registro em razão de atraso ou falta do
                    encaminhamento dos dados pelos diversos provedores de
                    informações conveniados. Em caso de duvidas entre em contato
                    com nosso suporte.
                  </div>
                  <div>
                    <div className="font-semibold">
                      Minuta de declaração LGPD
                    </div>
                    Declaro que a utilização dos dados respeitará as finalidades
                    e procedimentos legalmente admitidos pela lei geral de
                    proteção de dados divulgados em minha página inicial de
                    acesso.
                  </div>
                </Card>
              </>
            ) : (
              <Card>
                <div role="status" className="w-full animate-pulse">
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                </div>
              </Card>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SpcCPFQuery4;
